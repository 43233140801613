import React, { useState, useEffect } from "react";
import TextEditor from "../text-editor/TextEditor";

import Modal from "../modal/Modal";

import "./EstimateCommentaryEdit.css";

import { hasRoles } from "../../utils/roles";
import { translate } from "../../utils/translation";
import { postCommentary } from "./EstimateCommentaryEdit.functions";

export default function EstimateCommentaryEdit(props) {
  const { project } = props;
  const { user } = props;
  const { comment } = props;
  const { setComments } = props;
  const { part } = props;
  const { CP, CPs } = props;
  const { URLs } = props;
  const { CostId } = props;
  const { Module } = props;

  const [modal, setModal] = useState(false);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        Component={EditCommentary}
        title={translate("Edit Commentary")}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        // Component Props
        project={project}
        comment={comment}
        setComments={setComments}
        part={part}
        CPs={CPs}
        CP={CP}
        URLs={URLs}
        CostId={CostId}
        Module={Module}
      />
      <button
        className="commentary-edit-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-pencil-alt"></i>
      </button>
    </div>
  );
}

export function EditCommentary(props) {
  const { project } = props;
  const { setComments } = props;
  const { comment } = props;
  const { setShow } = props;
  const { part } = props;
  const { CPs } = props;
  const { URLs } = props;
  const { CostId } = props;
  const { Module } = props;

  const [title] = useState("");
  const [showTitle] = useState(true);
  const [customTitle, setCustomTitle] = useState("");
  const [text, setText] = useState("");
  const [version, setVersion] = useState("");

  useEffect(() => {
    setCustomTitle(comment.title);
  }, [comment.title]);

  useEffect(() => {
    setText(comment.text);
  }, [comment.text]);

  useEffect(() => {
    setVersion(comment.stage + "." + comment.revision);
  }, [comment.stage, comment.revision]);

  const showHideTitleInput = showTitle
    ? "commentary-title-flexbox"
    : "commentary-title-none";

  return (
    <div className="estimate-commentary-edit">
      <div className={showHideTitleInput}>
        <label className="label-title-input">{translate("Edit Title")}:</label>
        <input
          className="input-custom-title"
          value={customTitle}
          onChange={(e) => {
            setCustomTitle(e.target.value);
          }}
        />
      </div>
      <TextEditor text={text} setText={setText} />
      <div className="general-button-container">
        <button
          className="general-upload-button"
          onClick={() => {
            const data = {
              CostId: CostId,
              commentary_id: comment.id,
              project_id: project.id,
              project: project,
              stage: version.split(".")[0],
              revision: version.split(".")[1],
              title: title,
              text: text,
              part: part,
              setComments: setComments,
              showTitle: showTitle,
              customTitle: customTitle,
              setShow: setShow,
              CPs: CPs,
              Module: Module,
            };
            postCommentary(data, URLs);
          }}
        >
          {translate("Update")}
        </button>
      </div>
    </div>
  );
}
