import React, { useState } from "react";
import { editElement, deleteElement } from "../../api/cbs/CbsAPI";
import { CbsName } from "./CbsAdmin.components";
import { AutoCloseModal } from "../../components/modal/Modal";

export function EditElementModal(props) {
  const { element } = props;
  const { cbs } = props;
  const { setModal } = props;

  const [code, setCode] = useState(element.code || "");
  const [description, setDescription] = useState(element.description || "");
  const [unit, setUnit] = useState(element.unit || "");

  const [message, setMessage] = useState("");

  if (cbs?.data?.isPublished) {
    return (
      <div className="cbs-modal">
        <div className="general-button-container">
          <div>You cannot change an Element for a published CBS.</div>
        </div>
      </div>
    );
  }

  return (
    <div className="cbs-modal">
      <div className="general-row-container">
        <div className="cbs-input-field">Code</div>
        <div className="cbs-input-field">Description</div>
        <div className="cbs-input-field">Elemental Unit</div>
      </div>
      <div className="general-row-container">
        <CbsName get={code} set={setCode} />
        <CbsName get={description} set={setDescription} />
        <CbsName get={unit} set={setUnit} />
      </div>
      <div className="general-row-container">{message}</div>

      <div className="general-row-container">
        <EditElementButton
          cbs={cbs}
          elementId={element.id}
          code={code}
          description={description}
          unit={unit}
          setModal={setModal}
          setMessage={setMessage}
        />
      </div>

      <div className="general-button-container">
        <DeleteElementButton
          elementId={element.id}
          cbs={cbs}
          setModal={setModal}
          setMessage={setMessage}
        />
      </div>
    </div>
  );
}

function EditElementButton(props) {
  const { cbs } = props;
  const { elementId, code, description, unit } = props;
  const { setModal } = props;
  const { setMessage } = props;

  return (
    <button
      id={"cbs-admin-element-modal-update-button"}
      className="general-upload-button"
      onClick={async () => {
        setMessage("Updating...");
        const cbsId = cbs?.data?.id;
        const typeCode = cbs?.data?.typeCode;

        let result = await editElement(
          cbsId,
          elementId,
          code,
          description,
          unit
        );
        if (result.success) {
          setMessage(result.message);
          AutoCloseModal(setModal);
          cbs.set(await cbs.get(typeCode));
        } else {
          setMessage(result.message);
        }
      }}
    >
      Update
    </button>
  );
}

function DeleteElementButton(props) {
  const { cbs } = props;
  const { elementId } = props;
  const { setModal } = props;
  const { setMessage } = props;

  return (
    <button
      id={"cbs-admin-element-modal-delete-button"}
      className="general-modal-button"
      onClick={async () => {
        setMessage("Deleting...");

        const cbsId = cbs?.data?.id;
        const typeCode = cbs?.data?.typeCode;

        let result = await deleteElement(cbsId, elementId);
        if (result.success) {
          setMessage(result.message);
          AutoCloseModal(setModal);
          cbs.set(await cbs.get(typeCode));
        } else {
          setMessage(result.message);
        }
      }}
    >
      Delete
    </button>
  );
}
