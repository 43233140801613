// React
import React, { useState } from "react";

import { hasRoles } from "../../utils/roles";
import { uploadTPI } from "./TenderPriceIndex.functions";

// Components
import Modal from "../modal/Modal";

// Styles
import "./TenderPriceIndexingUpload.css";

export default function TenderPriceIndexingUpload(props) {
  // Props
  const { setTPI } = props;
  const { user } = props;
  const { locations } = props;
  const { region } = props;
  const { country } = props;

  const [modal, setModal] = useState(false);

  // Decide to show upload button
  let subLocation = determineSubLocation();

  if (subLocation !== "tender-price-index") {
    return null;
  }

  if (!hasRoles(user.roles, ["Admin"])) {
    return null;
  }

  return (
    <div>
      <button
        className="tpi-control-button"
        id="tpi-upload-modal-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-upload" />
      </button>
      <Modal
        // Modal Props
        title={"Tender Price Index Upload"}
        Component={TenderPriceIndexingUploadModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        setShow={setModal}
        setTPI={setTPI}
        locations={locations}
        user={user}
        region={region}
        country={country}
      />
    </div>
  );
}

export function TenderPriceIndexingUploadModal(props) {
  const { setShow } = props;
  const { setTPI } = props;
  const { region } = props;
  const { country } = props;

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [error, setError] = useState({});

  return (
    <div className="tpi-upload">
      <div className="general-row-container">
        <FileTray file={filePreview} />
      </div>

      <div className="general-row-container">
        <Upload setFile={setFile} setFilePreview={setFilePreview} />
      </div>

      <div className="general-row-container">
        <ErrorText error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <button
          className="general-upload-button"
          id={"tpi-upload-button"}
          onClick={() => {
            uploadTPI(
              file,
              setError,
              setShow,
              setTPI,
              setFilePreview,
              region,
              country
            );
          }}
        >
          Upload
        </button>
      </div>
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}

function FileTray(props) {
  const { file } = props;
  let fileName = "No File Attached";

  if (file !== null && file !== undefined) {
    fileName = file.name;
  }

  if (typeof file === "string") {
    fileName = "Keep Existing File";
  }

  return (
    <div className="category-edit-file-display">
      <b>File:</b> {fileName}
    </div>
  );
}

function ErrorText(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function Upload(props) {
  const { setFile, setFilePreview } = props;

  return (
    <label className="general-modal-button">
      <input
        type="file"
        className="tpi-upload-button"
        id={"tpi-file-upload"}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            setFilePreview(e.target.files[0]);
          }
        }}
      />
      Attach File
    </label>
  );
}
