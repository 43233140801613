import React, { useState } from "react";
import { connect } from "react-redux";
import { hasRoles } from "../../utils/roles";

// Components
import BenchmarkTPICity from "./BenchmarkTPICity";
import Modal from "../modal/Modal";

// Style
import "./BenchmarkTPICityControl.css";

function BenchmarkingTPICityControl(props) {
  const { selectedBenchmarks } = props;
  const { setBenchmarking } = props;
  const { user } = props.auth;

  const [modal, setModal] = useState(false);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        title={"TPI Control"}
        Component={BenchmarkTPICity}
        modal={modal}
        setModal={setModal}
        // Component Props
        selectedBenchmarks={selectedBenchmarks}
        setBenchmarking={setBenchmarking}
        user={user}
      />
      <button
        className="benchmark-set-tpi-button"
        onClick={() => {
          setModal(true);
        }}
      >
        TPI Control
      </button>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(BenchmarkingTPICityControl);
