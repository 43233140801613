import React from "react";
import { translate } from "../../utils/translation";
import "./CostVsBudget.css";
import { comma } from "../../helpers/common.functions";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

CostVsBudget.propTypes = {
  firstValue: PropTypes.string,
  secondValue: PropTypes.string,
  firstText: PropTypes.string,
  secondText: PropTypes.string,
  differenceText: PropTypes.string,
  differenceValue: PropTypes.string,
  isApproved: PropTypes.bool,
};

export default function CostVsBudget(props) {
  let { firstValue, secondValue } = props;
  let { firstText, secondText, differenceText, differenceValue } = props;
  let { isApproved } = props;

  firstValue = parseInt(firstValue);
  secondValue = parseInt(secondValue);

  // Blank out if Base comparison is NaN
  if (isNaN(firstValue)) {
    firstValue = 0;
  }
  if (isNaN(secondValue)) {
    secondValue = 0;
  }

  if (!isApproved) {
    firstValue = "Not Approved";
    differenceValue = "N/A";
  }
  return (
    <div className="cost-plan-bar-link">
      <div className="cost-plan-bar">
        <div className="cost-plan-cost">
          <div className="cost-plan-cost-item">
            <div className="cost-plan-cost-text">{firstText}</div>
            <div className="cost-plan-cost-number">{comma(firstValue)}</div>
          </div>
          <div className="cost-plan-cost-item">
            <div className="cost-plan-cost-text">
              <b>{secondText}</b>
            </div>
            <div className="cost-plan-cost-number">
              <b>{comma(secondValue)}</b>
            </div>
          </div>
          <div className="cost-plan-cost-item">
            <div className="cost-plan-cost-text">{differenceText}</div>
            <div className="cost-plan-cost-number">
              {generateBrackets(comma(differenceValue))}
            </div>
          </div>
        </div>
        {isApproved && (
          <div className="cost-plan-stacked-bar">
            <BackgroundBar />
            <CostBar cost={secondValue} budget={firstValue} />
            <Line margin={"140px"} />
            <CostBarText cost={secondValue} budget={firstValue} />
          </div>
        )}
      </div>
    </div>
  );
}

function BackgroundBar() {
  let width = 200;
  return <div className="cost-plan-horizontal-bar" style={{ width: width }} />;
}

function CostBar(props) {
  const { cost, budget } = props;
  let ratio = (cost / budget) * 140;
  let values =
    "" +
    ratio * 0 +
    ";" +
    +(ratio * 0.25) +
    ";" +
    ratio * 0.5 +
    ";" +
    ratio * 0.75 +
    ";" +
    ratio * 1 +
    "";
  if (ratio === Infinity || isNaN(ratio)) {
    ratio = 0;
  }
  return (
    <div className="cost-plan-cost-bar">
      <svg height={"30px"} width={"200px"}>
        <defs>
          <linearGradient id="MyGradient">
            <stop offset="0%" stopColor="rgb(228, 144, 92)" />
            <stop offset="100%" stopColor="rgb(228, 97, 15)" />
          </linearGradient>
        </defs>
        <rect className="cost-plan-cost-bar-fill" height={15} width={ratio}>
          <animate
            attributeType="CSS"
            attributeName="width"
            from="0"
            to="1"
            values={values}
            keyTimes="0; 0.25; 0.50; 0.75; 1"
            dur="0.7s"
          />
        </rect>
      </svg>
    </div>
  );
}

function CostBarText(props) {
  const { cost, budget } = props;
  let ratio = Math.round((cost / budget) * 100);
  let text, value;

  // Normalise to a percentage
  if (cost > budget) {
    value = ratio - 100 + "%";
    text = "Above";
  } else {
    value = 100 - ratio + "%";
    text = "Below";
  }

  // If comparing with 0
  if (ratio === Infinity) {
    value = 100 + "%";
    text = "Above";
    ratio = 100;
  }

  if (isNaN(ratio)) {
    value = 0 + "%";
  }

  return (
    <div className="cost-plan-comparison-bar-label">
      <div className="cost-plan-comparison-bar-percentage">{value}</div>
      <div className="cost-plan-comparison-bar-text"> {translate(text)} </div>
      <div className="cost-plan-comparison-bar-value">
        <b>{comma(budget)}</b>
      </div>
    </div>
  );
}

function Line(props) {
  return (
    <div
      className="cost-plan-comparison-bar"
      style={{ marginLeft: props.margin }}
    ></div>
  );
}

function generateBrackets(string) {
  if (isEmpty(string)) {
    return "";
  }

  if (string.toString().includes("-")) {
    return "(" + string.split("-")[1] + ")";
  } else {
    return string;
  }
}
