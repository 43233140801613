import React, { useState } from "react";

import { submitForm } from "./ContactUsForm.functions";

import "./ContactUsForm.css";

export default function ContactUsForm() {
  const [fullName, setFullName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [notes, setNotes] = useState("");

  const [error, setError] = useState({});

  const data = {
    payload: {
      fullName: fullName,
      businessName: businessName,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
      notes: notes,
      completed: false,
      contacted: false,
    },
    setError: setError,
  };

  return (
    <div className="request-demo">
      <RequestDemoSection />
      <div className="request-demo-para">
        <div className="display-4">
          If you would like to contact us, please complete the form below.
        </div>
      </div>
      <Input value={fullName} onChange={setFullName} label={"Full Name"} />
      <Input
        value={businessName}
        onChange={setBusinessName}
        label={"Business Name"}
      />
      <Input
        value={phoneNumber}
        onChange={setPhoneNumber}
        label={"Phone Number"}
      />

      <Input
        value={emailAddress}
        onChange={setEmailAddress}
        label={"Email Address"}
      />

      <Input value={notes} onChange={setNotes} label={"Notes"} />

      <div className="request-demo-button-container">
        <div className="request-demo-button-error"> </div>
        <Submit data={data} error={error} />
        <DisplayError error={error} />
      </div>
    </div>
  );
}

function Submit(props) {
  const { data } = props;

  return (
    <button
      className="request-demo-button"
      onClick={() => {
        submitForm(data);
      }}
    >
      Submit Form
    </button>
  );
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="request-demo-button-error">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function RequestDemoSection() {
  return (
    <div className="request-demo-title">
      <h1 className="display-4">Contact Us</h1>
    </div>
  );
}

function Input(props) {
  const { label, value, onChange } = props;
  return (
    <div className="request-demo-input-row">
      <div className="request-demo-input-label">
        <div className="display-4">{label}</div>
      </div>
      <input
        className="request-demo-input"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      ></input>
    </div>
  );
}
