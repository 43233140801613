import React from "react";
import { MultiValueRow } from "./BenchmarkMetricsView.components";
import "./BenchmarkProjectInfoView.css";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

/// props validation | SQ(javascript:S6774)
BenchmarkMetricsView.propTypes = {
  rowData: PropTypes.object,
};
export default function BenchmarkMetricsView(props) {
  const { rowData } = props;

  let combinedRows = prepareBenchmarkMetricsTable(rowData);
  if (combinedRows.length === 1) {
    return (
      <div className="no-data-available">
        <i className="fas fa-thumbs-down"></i> No Metrics Data Available
      </div>
    );
  }
  return (
    <div className="create-benchmark-container">
      <div className="create-benchmark-form-container">
        <div className="create-project-panel create-project-matrix-panel">
          <div>
            <TitledTable rows={combinedRows} />
          </div>
        </div>
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
TitledTable.propTypes = {
  rows: PropTypes.array,
  heading: PropTypes.string,
};
export function TitledTable(props) {
  const { rows, heading } = props;
  return (
    <div>
      <h5>{heading}</h5>
      {rows}
    </div>
  );
}
/// props validation | SQ(javascript:S6774)
TableSubHeader.propTypes = {
  subHeading: PropTypes.string,
};
export function TableSubHeader(props) {
  const { subHeading } = props;
  return (
    <div className="pctm-container-horizontal pctm-container-matrix-horizontal">
      <div className="pctm-row pctm-matrix-row pctm-matrix-subheader">
        {subHeading}
      </div>
    </div>
  );
}

export function prepareBenchmarkMetricsTable(rowData) {
  const metricKeys = ["code", "metric", "quantity", "unit"];

  const combinedRows = [];

  if (isEmpty(rowData)) {
    return [<div key="nodata">No Metrics Data Avaialble</div>];
  }

  //Filter Design Metrics from all Metrics
  const designMetrics = rowData.filter((item) =>
    item.code.toLowerCase().includes("d")
  );
  //Filter Cost Metrics from all Metrics
  const costMetrics = rowData.filter((item) =>
    item.code.toLowerCase().includes("c")
  );
  //Filter All other Metrics from all metrics
  const restOfMetrics = rowData.filter((item) =>
    item.code.toLowerCase().includes("e")
  );

  //DesignMetrics
  if (designMetrics.length > 0) {
    combinedRows.push(
      <TableSubHeader subHeading="Design Benchmarks"></TableSubHeader>
    );
    designMetrics.forEach((item) => {
      combinedRows.push(<MultiValueRow itemData={item} keys={metricKeys} />);
    });
  }

  //CostMetrics
  if (costMetrics.length > 0) {
    combinedRows.push(
      <TableSubHeader subHeading="Cost Benchmarks"></TableSubHeader>
    );
    costMetrics.forEach((item) => {
      combinedRows.push(<MultiValueRow itemData={item} keys={metricKeys} />);
    });
  }

  //Other Elemental Metrics
  if (restOfMetrics.length > 0) {
    combinedRows.push(
      <TableSubHeader subHeading="Client Specific Benchmarks"></TableSubHeader>
    );
    restOfMetrics.forEach((item) => {
      combinedRows.push(<MultiValueRow itemData={item} keys={metricKeys} />);
    });
  }
  return combinedRows;
}
