import { authHeader } from "../../_helpers/auth-header";
import { pc2APIs } from "../api-endpoints/PostContract2.0Endpoints";

export async function onDelete(data) {
  const { packageId } = data;
  const { projectId } = data;
  const { postContractId } = data;
  const { postContractReports } = data;

  let result = window.confirm(
    `This will permanently delete all data associated with this uploaded sheet, including information displayed on other pages. Confirm?`
  );

  if (!result) {
    return null;
  }

  // POST CONTRACT API
  const url =
    pc2APIs().delete_post_contract +
    "?postContractId=" +
    postContractId +
    "&projectId=" +
    projectId +
    "&packageId=" +
    packageId;

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    const URL = window.location.href;
    const pagename = URL.substring(URL.lastIndexOf("/") + 1);
    postContractReports.set(
      await postContractReports.get(projectId, packageId, pagename)
    );
  } else {
    console.log("Post Contract Delete Error");
  }
}
