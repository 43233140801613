import React, { useState } from "react";
import DetailedComparisonTable from "./DetailedComparisonTable";
import ClusteredBarChart from "../charts/ClusteredBarChart";
import { translate } from "../../utils/translation";
import "./DetailedComparison.css";
import isEmpty from "../../validation/is-empty";

export default function DetailedComparison(props) {
  const { e, i, costPlan, formerProps, reconcile, index } = props;
  const { reconciliationData } = props;
  const { project } = props;

  const [show, setShow] = useState("Graph");

  let graphView;
  let tableView;
  let switchView = "";
  if (show === "Graph") {
    graphView = "detailed-comparison-container";
    tableView = "detailed-comparison-container-none";
  }

  if (show === "Table") {
    graphView = "detailed-comparison-container-none";
    tableView = "detailed-comparison-container";
  }
  if (isEmpty(e.designContingency) || isEmpty(e.changes)) {
    graphView = "detailed-comparison-container";
    tableView = "detailed-comparison-container";
    switchView = "detailed-comparison-container-none";
  }

  if (reconciliationData.reconciliationType === "RELEASE") {
    graphView = "detailed-comparison-container";
    tableView = "detailed-comparison-container";
    switchView = "detailed-comparison-container-none";
  }

  return (
    <div>
      <div className={switchView}>
        <div className="recon-section-title">
          <h1 className="display-4">{translate("Detailed Comparison")}</h1>
          <ComparisonToggle />
        </div>
      </div>
      <div className="detailed-comparison">
        <div className={graphView}>
          <ClusteredBarChart project={project} deltas={e} />
        </div>
        <div className={tableView}>
          <DetailedComparisonTable
            project={project}
            deltas={e}
            i={i}
            e={e}
            costPlan={costPlan}
            formerProps={formerProps}
            reconcile={reconcile}
            index={index}
          />
        </div>
      </div>
    </div>
  );

  function ComparisonToggle() {
    let graphToggle;
    let tableToggle;
    if (show === "Graph") {
      graphToggle = "recon-comarison-toggle-button-in-left";
      tableToggle = "recon-comarison-toggle-button-out-right";
    } else {
      graphToggle = "recon-comarison-toggle-button-out-left";
      tableToggle = "recon-comarison-toggle-button-in-right";
    }

    return (
      <div className="recon-comparison-toggle">
        <button
          className={graphToggle}
          onClick={() => {
            setShow("Graph");
          }}
        >
          <h1 className="display-4">{translate("Graph")}</h1>
        </button>
        <button
          className={tableToggle}
          onClick={() => {
            setShow("Table");
          }}
        >
          <h1 className="display-4">{translate("Table")}</h1>
        </button>
      </div>
    );
  }
}
