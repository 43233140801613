import React, { useState } from "react";
import { connect } from "react-redux";
import BenchmarkUpload from "./BenchmarkUpload";
import Modal from "../modal/Modal";
import { BA_CBS_CM_ROLE, hasRoles } from "../../utils/roles";
import PropTypes from "prop-types";

import "./BenchmarkUploadModal.css";

/// props validation | SQ(javascript:S6774)
ModalBenchmarkingUploader.propTypes = {
  setBenchmarking: PropTypes.func,
  user: PropTypes.object,
  roles: PropTypes.object,
  auth: PropTypes.object,
};

function ModalBenchmarkingUploader(props) {
  const { setBenchmarking } = props;
  const { user } = props.auth;

  const [modal, setModal] = useState(false);

  if (!hasRoles(user.roles, BA_CBS_CM_ROLE)) {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        title={"Upload Benchmark"}
        Component={BenchmarkUpload}
        modal={modal}
        setModal={setModal}
        // Component Props
        setBenchmarking={setBenchmarking}
        setShow={setModal}
      />
      <button
        className="benchmark-control-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-upload" />
      </button>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(ModalBenchmarkingUploader);
