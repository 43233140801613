import React, { useState } from "react";

import { translate, tranStr } from "../../utils/translation";
import "./ValueEngineeringUploader.css";

export default function ValueEngineeringUploader(props) {
  const { data } = props;
  const { uploadFunction } = props;
  const { isValueEngineering } = props;

  const [file, setFile] = useState(null);
  const [error, setError] = useState({});
  const [estimate, setEstimate] = useState("");

  data.file = file;
  data.setError = setError;
  data.error = error;

  return (
    <div className="pdu-container">
      <div className="general-row-container">
        <ValueEngineeringLink isValueEngineering={isValueEngineering} />
      </div>
      <div className="general-row-container">
        <AddEstimate
          data={data}
          estimate={estimate}
          setEstimate={setEstimate}
        />
      </div>
      <div className="general-row-container">
        <UploaderFileTray file={file} />
      </div>
      <div className="general-row-container">
        <UploadFile setFile={setFile} />
      </div>
      <div className="general-row-container">
        <UploadDataError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <UploadData data={data} uploadFunction={uploadFunction} />
      </div>
    </div>
  );
}

function AddEstimate(props) {
  const { estimate, setEstimate } = props;
  const { data } = props;

  const handleEstimateChange = (e) => {
    setEstimate(e.target.value);
    data.estimate = e.target.value;
  };

  return (
    <div className="value-engineering-upload-control-row">
      <div className="value-engineering-upload-control-label">
        {translate("Select Estimate")}:
      </div>
      <select
        value={estimate}
        className="value-engineering-upload-control-input "
        onChange={handleEstimateChange}
      >
        <option value="">-- Select Estimate --</option>
        {data?.CP?.estimates && selectEstimate(data.CP.estimates)}
      </select>
    </div>
  );
}

function UploadFile(props) {
  const { setFile } = props;

  return (
    <label className="general-modal-button">
      <input
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
          }
        }}
      />
      {translate("Attach File")}
    </label>
  );
}

function selectEstimate(array) {
  return array.map(function (item) {
    if (item.name !== "") {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    } else {
      return null;
    }
  });
}

function UploaderFileTray(props) {
  const { file } = props;
  let fileName = tranStr("No File Added");

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="pdu-file-display">
      <b>{translate("File")}:</b> {fileName}
    </div>
  );
}

function UploadData(props) {
  const { data } = props;
  const { uploadFunction } = props;

  return (
    <button
      className="general-upload-button"
      onClick={async () => {
        uploadFunction(data);
      }}
    >
      {translate("Upload")}
    </button>
  );
}

function UploadDataError(props) {
  const { error } = props;
  return (
    <div className="pdu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function ValueEngineeringLink(props) {
  const { isValueEngineering } = props;

  if (!isValueEngineering) {
    return null;
  }

  return (
    <div className="cost-planning-upload-control-row">
      <div className="cost-planning-upload-control-label">
        {translate("VE Upload Template")}:
      </div>
      <a
        className="cost-planning-upload-document-button"
        href={
          "https://stcostclarityprd001.blob.core.windows.net/1-guides/Cost_Clarity_-_Value_Engineering_Template.xlsx"
        }
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        {translate("Download")}
      </a>
    </div>
  );
}
