import React, { useState } from "react";

import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";
import { tranStr } from "../../utils/translation";

import { SAVE_MESSAGES } from "../estimates/EstimatesTPISaveMessages";
import { VALUE_ENGINEERING } from "./ValueEngineering.strings";
import { saveValueEngineeringItemsStatus } from "./ValueEngineering.functions";
import { BoxSet as ValueEngineeringStatusControl } from "./ValueEngineeringStatusControl";

export default function ValueEngineeringTable(props) {
  const { data } = props;
  const { project } = props;
  const { user } = props;
  const { CPs } = props;
  const { setModal } = props;
  const { setSelectedVeItemId } = props;
  const { setIsModalReadonly } = props;
  const { veItemsOriginal } = props;
  const { veItems, setVeItems } = props;

  if (isEmpty(data) || isEmpty(data.value_engineering_items)) {
    return (
      <div className="ve-table-missing-info">
        {VALUE_ENGINEERING.WARNINGS.VM_NOT_YET_ADDED}
      </div>
    );
  }

  return (
    <ValueEngineeringTableBody
      data={data}
      project={project}
      user={user}
      CPs={CPs}
      veItemsOriginal={veItemsOriginal}
      veItems={veItems}
      setVeItems={setVeItems}
      setModal={setModal}
      setSelectedVeItemId={setSelectedVeItemId}
      setIsModalReadonly={setIsModalReadonly}
    />
  );
}

function ValueEngineeringTableBody(props) {
  const { data } = props;
  const { project } = props;
  const { user } = props;
  const { CPs } = props;
  const { setModal } = props;
  const { setSelectedVeItemId } = props;
  const { setIsModalReadonly } = props;
  const { veItemsOriginal } = props;
  const { veItems, setVeItems } = props;

  const [isEditActive, setIsEditActive] = useState(false);

  const isUserClient = user?.roles.includes("Client") || false;
  const isProjectPhaseCostPlanning = project.project_phase === "COST_PLANNING";

  const isValueEngineeringTableEditable =
    isUserClient && isProjectPhaseCostPlanning;

  let tableClass = `ve-table ebt ${isEditActive ? "edit-active" : ""}`;

  return (
    <div className={tableClass}>
      <div className="ve-head-row-container">
        <EstimateBudgetTableHead />
        {isValueEngineeringTableEditable && (
          <EstimateBudgetTableHeadControls
            CPs={CPs}
            project={project}
            data={data}
            veItems={veItems}
            veItemsOriginal={veItemsOriginal}
            isEditActive={isEditActive}
            setIsEditActive={setIsEditActive}
          />
        )}
      </div>
      <EstimateBudgetTableRows
        data={data}
        isEditActive={isEditActive}
        veItems={veItems}
        setVeItems={setVeItems}
        setModal={setModal}
        setSelectedVeItemId={setSelectedVeItemId}
        setIsModalReadonly={setIsModalReadonly}
      />
    </div>
  );
}

function trackStatusChange(veItems, veItemsOriginal) {
  const veItemsStatusChanged = [];
  for (let i = 0; i < veItems.length; i++) {
    const { id, status } = veItems[i];
    if (status !== veItemsOriginal[i]?.status) {
      veItemsStatusChanged.push({ id, status });
    }
  }
  const isSame = veItemsStatusChanged.length === 0;
  return { isSame, veItemsStatusChanged };
}

function EstimateBudgetTableHeadControls(props) {
  const { CPs, project } = props;
  const { data } = props;
  const { veItems, veItemsOriginal } = props;
  const { isEditActive, setIsEditActive } = props;

  const [message, setMessage] = useState(SAVE_MESSAGES.NONE);

  const { isSame, veItemsStatusChanged } = trackStatusChange(
    veItems,
    veItemsOriginal
  );

  if (isSame && message === SAVE_MESSAGES.NOT_SAVED) {
    setMessage(SAVE_MESSAGES.NONE);
  }

  if (!isSame && message === SAVE_MESSAGES.NONE && isEditActive) {
    setMessage(SAVE_MESSAGES.NOT_SAVED);
  }

  async function handleSave(e) {
    setMessage(SAVE_MESSAGES.SAVING);

    const response = await saveValueEngineeringItemsStatus(
      data,
      veItemsStatusChanged
    );

    if (response.ok) {
      setIsEditActive(false);
      await CPs.set(await CPs.get(project.id, project));
      setMessage(SAVE_MESSAGES.SAVED);
    } else {
      setMessage(SAVE_MESSAGES.SAVE_FAILED);
    }

    setTimeout(() => {
      setMessage(SAVE_MESSAGES.NONE);
    }, 1000);
  }

  const isSavePending = message === SAVE_MESSAGES.NOT_SAVED;
  const isSaveInProgress =
    message === SAVE_MESSAGES.SAVING || message === SAVE_MESSAGES.SAVED;
  const showSaveButton = isSavePending || isSaveInProgress;
  const showEditButton = !showSaveButton;

  return (
    <div className="ve-table-controls-container">
      {showEditButton && (
        <button
          className="ve-table-control edit-button"
          title={tranStr("Change Status")}
          onClick={(e) => setIsEditActive(!isEditActive)}
        >
          <i className="fas fa-pencil-alt"></i>
        </button>
      )}
      {showSaveButton && (
        <>
          <button
            className="ve-table-control save-button"
            title={tranStr("Save changes")}
            onClick={handleSave}
            disabled={isSaveInProgress}
          >
            <i className="fas fa-save"></i>
          </button>
          <div className="estimates-TPI-save-message">
            <b>{message}</b>
          </div>
        </>
      )}
    </div>
  );
}

function EstimateBudgetTableRows(props) {
  const { veItems, setVeItems } = props;
  const { isEditActive } = props;
  const { setModal } = props;
  const { setSelectedVeItemId } = props;
  const { setIsModalReadonly } = props;

  const display = [];
  let rowType = "value-engineering-row";

  veItems.forEach((row, index) => {
    const refNumber = index + 1;

    display.push(
      <EstimateBudgetRow
        key={row.id}
        id={row.id}
        row={row}
        rowType={rowType}
        refNumber={refNumber}
        isEditActive={isEditActive}
        veItems={veItems}
        setVeItems={setVeItems}
        setModal={setModal}
        setSelectedVeItemId={setSelectedVeItemId}
        setIsModalReadonly={setIsModalReadonly}
      />
    );
  });

  display.push(
    <EstimateBudgetTotalRow
      key={"ve-total-row"}
      rowType={rowType}
      veItems={veItems}
    />
  );

  return <div>{display}</div>;
}

function EstimateBudgetTableHead() {
  return (
    <div className="value-engineering-head-row">
      <div className="budget-cell-head ve-ref-cell-head">
        <b>{VALUE_ENGINEERING.FIELDS.REF}</b>
      </div>
      <div className="budget-description-cell ve-description-cell">
        <b>{VALUE_ENGINEERING.FIELDS.TITLE}</b>
      </div>
      <div className="budget-cell-head ve-package-location-head">
        <b>{VALUE_ENGINEERING.FIELDS.WORK_PACKAGE}</b>
      </div>
      <div className="budget-cell-head ve-package-location-head">
        <b>{VALUE_ENGINEERING.FIELDS.LOCATION}</b>
      </div>
      <div className="budget-cell-head">
        <b>{VALUE_ENGINEERING.FIELDS.VALUE}</b>
      </div>
      <div className="budget-cell-head approved">
        <b>{VALUE_ENGINEERING.FIELDS.APPROVED_COST}</b>
      </div>
      <div className="budget-cell-head forreview">
        <b>{VALUE_ENGINEERING.FIELDS.FORREVIEW_COST}</b>
      </div>
      <div className="budget-cell-head rejected">
        <b>{VALUE_ENGINEERING.FIELDS.REJECTED_COST}</b>
      </div>
      <div className="budget-cell-head ve-status-cell-head">
        <b>{VALUE_ENGINEERING.FIELDS.STATUS}</b>
      </div>
    </div>
  );
}

function useStatus({ id, veItems, setVeItems }) {
  const _veItems = [...veItems];
  const _veItem = _veItems.find((row) => row.id === id);

  const _setStatus = function (status) {
    _veItem.status = status;
    setVeItems(_veItems);
  };

  return [_veItem.status, _setStatus];
}

function EstimateBudgetRow(props) {
  const { row, rowType, refNumber } = props;
  const { isEditActive } = props;
  const { setModal } = props;
  const { setSelectedVeItemId } = props;
  const { setIsModalReadonly } = props;

  const [status, setStatus] = useStatus(props);

  return (
    <div className="estimate-breakdown-row-wrapper">
      <button
        className={rowType}
        onClick={(e) => {
          if (!isEditActive) {
            setSelectedVeItemId(row.id);
            setModal(true);
            setIsModalReadonly(true);
          }
        }}
      >
        <div
          className="budget-cell ve-ref-cell"
          style={{ justifyContent: "center" }}
        >
          <b>{refNumber}</b>
        </div>
        <div className="budget-description-cell ve-description-cell">
          {row.title}
        </div>
        <div className="budget-cell ve-package-location-cell">
          {row.work_package}
        </div>
        <div className="budget-cell ve-package-location-cell">
          {row.location}
        </div>
        <div className="budget-cell">
          {row.value ? comma(Math.round(row.value)) : 0}
        </div>
        <div className="budget-cell">
          {row.value && status === VALUE_ENGINEERING.STATUS.APPROVED
            ? comma(Math.round(row.value))
            : 0}
        </div>
        <div className="budget-cell">
          {row.value && status === VALUE_ENGINEERING.STATUS.FORREVIEW
            ? comma(Math.round(row.value))
            : 0}
        </div>
        <div className="budget-cell">
          {row.value && status === VALUE_ENGINEERING.STATUS.REJECTED
            ? comma(Math.round(row.value))
            : 0}
        </div>
        <div className="status-cell ve-status-cell">
          <ValueEngineeringStatusControl
            status={status}
            setStatus={setStatus}
            readonly={!isEditActive}
          />
        </div>
      </button>
    </div>
  );
}

function EstimateBudgetTotalRow(props) {
  const { rowType } = props;
  const { veItems } = props;

  return (
    <div className="estimate-breakdown-row-wrapper">
      <div className={rowType + " totalrow"}>
        <div
          className="budget-cell ve-ref-cell"
          style={{ justifyContent: "center" }}
        ></div>
        <div className="budget-description-cell ve-description-cell">
          <b>Total</b>
        </div>
        <div className="budget-cell ve-package-location-cell"></div>
        <div className="budget-cell ve-package-location-cell"></div>
        <div className="budget-cell">
          {comma(
            Math.round(
              veItems.reduce((sum, record) => {
                return sum + record.value;
              }, 0)
            )
          )}
        </div>
        <div className="budget-cell approved">
          {comma(
            Math.round(veTotals(veItems, VALUE_ENGINEERING.STATUS.APPROVED))
          )}
        </div>
        <div className="budget-cell forreview">
          {comma(
            Math.round(veTotals(veItems, VALUE_ENGINEERING.STATUS.FORREVIEW))
          )}
        </div>
        <div className="budget-cell rejected">
          {comma(
            Math.round(veTotals(veItems, VALUE_ENGINEERING.STATUS.REJECTED))
          )}
        </div>
        <div className="status-cell ve-status-cell"></div>
      </div>
    </div>
  );
}

function veTotals(veItems, status) {
  return veItems.reduce(function (sum, record) {
    return record.status !== status ? sum : sum + record.value;
  }, 0);
}
