import React, { useState, useEffect } from "react";

import Modal from "../modal/Modal";

import { submitEdit } from "./EstimatesEdit.functions";
import { hasRoles } from "../../utils/roles";
import { translate, tranStr } from "../../utils/translation";

import "./EstimatesEdit.css";

export default function EstimatesEdit(props) {
  const { project } = props;
  const { estimate } = props;
  const { user } = props;
  const { CPs } = props;

  const [modal, setModal] = useState(false);

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="estimate-edit-wrapper">
      <Modal
        // Modal Props
        Component={EditEstimate}
        title={tranStr("Edit Estimate")}
        modal={modal}
        setModal={setModal}
        // Component Props
        project={project}
        CPs={CPs}
        estimate={estimate}
      />
      <button
        className="estimate-edit-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-pencil-alt"></i>
      </button>
    </div>
  );
}

function EditEstimate(props) {
  const { project } = props;
  const { estimate } = props;
  const { CPs } = props;
  const { setModal } = props;

  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [stage, setStage] = useState("");
  const [revision, setRevision] = useState("");
  const [part, setPart] = useState("");
  const [isIncluded, setIsIncluded] = useState("");

  const [error, setError] = useState(false);

  useEffect(() => {
    setName(estimate.name);
    setDate(estimate.date);
    setStage(estimate.stage);
    setRevision(estimate.revision);
    setPart(estimate.part);
    setIsIncluded(estimate.is_included);
  }, [estimate, props.selectedStage]);

  function updateEstimate() {
    const data = {
      estimate_id: estimate.id,
      project_id: project.id,
      project: project,
      name: name,
      date: date,
      stage: stage,
      revision: revision,
      part: part,
      isIncluded: isIncluded,
      CPs: CPs,
    };

    data.setError = setError;
    data.setModal = setModal;

    submitEdit(data);
  }

  return (
    <div className="edit-estimate">
      <div className="edit-estimate-container">
        <div className="edit-estimate-label">
          <b>{tranStr("File Name")}:</b>
        </div>
        {estimate.file_name}
      </div>
      <div className="edit-estimate-container">
        <div className="edit-estimate-label">
          <b>{tranStr("Estimate ID")}:</b>
        </div>
        {estimate.id}
      </div>
      <div className="edit-estimate-container">
        <div className="edit-estimate-label">
          <b>{tranStr("CBS")}:</b>
        </div>
        {estimate.standard}
      </div>
      {inputField(tranStr("Name") + ":", name, setName)}
      {inputField(tranStr("Date (YYYY-MM-DD)") + ":", date, setDate)}
      {inputField(tranStr("List Order") + ":", part, setPart)}
      {checkBox(
        tranStr("Included in Cost Plan") + ":",
        isIncluded,
        setIsIncluded
      )}
      <div className="general-row-container">
        <DisplayError error={error} />
      </div>
      <div className="general-button-container">
        <button
          id={"estimate-edit-update-button"}
          className="general-upload-button"
          onClick={updateEstimate}
        >
          {translate("Update")}
        </button>
      </div>
    </div>
  );
}

function inputField(title, value, setFunction) {
  return (
    <div>
      <div className="edit-estimate-container">
        <div className="edit-estimate-label">
          <b>{title}</b>
        </div>
        <input
          id={"edit-estimate-field-id"}
          className="edit-estimate-field"
          value={value}
          onChange={(e) => {
            setFunction(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

function checkBox(title, value, setFunction) {
  return (
    <div className="edit-estimate-container">
      <div className="edit-estimate-label">
        <b>{title}</b>
      </div>
      <input
        id={"edit-estimate-checkbox"}
        type="checkbox"
        checked={value}
        onChange={(e) => {
          const theValue = e.target.checked;
          setFunction(theValue);
        }}
      />
    </div>
  );
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="pdu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
