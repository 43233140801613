import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import { Notify } from "../post-contract-commentary/Clarifications";
import Table from "../table/Table";

// Functions
import { tranStr } from "../../utils/translation";
import { onSetCriticalVariations } from "../../api/post-contract/PostContractCriticalAPI";

PostContractCriticalSelection.propTypes = {
  setShow: PropTypes.func,
  data: PropTypes.object,
  postContract: PropTypes.object,
  url: PropTypes.string,
  sheetName: PropTypes.string,
  postContractId: PropTypes.string,
  projectId: PropTypes.string,
};

// Styles
export default function PostContractCriticalSelection(props) {
  const { setShow } = props;
  const { data } = props;
  const { postContract } = props;
  const { url } = props;
  const { sheetName } = props;
  const { postContractId } = props;
  const { projectId } = props;
  const [error, setError] = useState({});

  const [selectedItems, setSelectedItems] = useState(
    data.filter((r) => r["is_critical"] === "true")
  );

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  return (
    <div>
      <Table
        tableArray={data}
        columns={columns()}
        tableSize={10}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Checked Selection
        checked={selectedItems}
        setChecked={setSelectedItems}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal}
        modal={modal}
      />
      <div className="general-row-container">
        <Notify error={error} setError={setError} />
      </div>
      <div className="general-row-container-double">
        <button
          className="create-project-save-cost-managers-button"
          onClick={() => {
            // IDs of the selected variations will be the critical ones
            const isCriticalIDs = selectedItems.map((e) => e.id);

            // The difference between the total set and the critical set is the non-critical set
            const notCriticalIDs = data
              .map((e) => e.id)
              .filter((e) => !isCriticalIDs.some((v) => e === v));

            const dataObject = {
              postContract: postContract,
              notCriticalIDs: notCriticalIDs,
              isCriticalIDs: isCriticalIDs,
              postContractId,
              projectId,
              sheetName,
              url: url,
              setError: setError,
              setShow: setShow,
            };

            onSetCriticalVariations(dataObject);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export function columns() {
  return [
    {
      type: "CHECK",
      key: "id",
      width: 50,
      isModal: false,
    },
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 100,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 500,
      isFiltered: true,
    },
  ];
}
