import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

import Table from "../table/Table";
import Modal from "../modal/Modal";
import PostContractCriticalSelection from "./PostContractCriticalSelection";

import { postContractTableAdaptor } from "./PostContractAdaptor.functions";
import { PostContractTableModal } from "./PostContractTableModal";

import "./PostContractTable.css";
import { pc2APIs } from "../../api/api-endpoints/PostContract2.0Endpoints";

PostContractTable.propTypes = {
  postContract: PropTypes.object,
  isTable: PropTypes.func,
  pageSize: PropTypes.number,
  component: PropTypes.object,
  config: PropTypes.object,
  selectedReport: PropTypes.object,
};

export default function PostContractTable(props) {
  const { postContract } = props;
  const { isTable } = props;
  const { pageSize } = props;
  const { component, config } = props;
  const { selectedReport } = props;

  const [selectedRow, setSelectedRow] = useState({});

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const { columns, rows } = postContractTableAdaptor(component, config);

  if (isEmpty(columns)) {
    return null;
  }

  let style = "post-contract-table";
  if (isTable) {
    style = "";
  }

  return (
    <div className={style}>
      <Table
        title={component?.Heading}
        tableArray={rows}
        columns={columns}
        tableSize={pageSize || rows.length}
        isTitleDisabled={!isTable}
        isTotalDisabled={!isTable}
        isFilterDisabled={!isTable}
        isPaginationDisabled={!isTable}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal1}
      />
      <Modal
        // Modal Props
        title={component?.Heading}
        Component={PostContractTableModal}
        modal={modal1}
        setModal={setModal1}
        // Component Props
        columns={columns}
        selectedRow={selectedRow}
      />

      {component?.HasCriticalSelection && (
        <div>
          <Modal
            // Modal Props4
            title={"Select Critical"}
            Component={PostContractCriticalSelection}
            modal={modal2}
            setModal={setModal2}
            // Component Props
            setShow={setModal2}
            data={rows}
            columns={columns}
            label={"Select Critical"}
            postContract={postContract}
            postContractId={selectedReport.PostContractId}
            projectId={selectedReport.ProjectId}
            url={pc2APIs().save_critical_variations}
            sheetName={component.Heading}
          />
          <button
            className="general-upload-button"
            onClick={() => {
              setModal2(true);
            }}
          >
            {"Select Critical"}
          </button>
        </div>
      )}
    </div>
  );
}
