import React from "react";
import { translate } from "../../utils/translation";

export default function ReconciliationToggle(props) {
  const { state, setState } = props;
  const { on } = props;

  let onState;
  let offState;
  if (state === on) {
    onState = "recon-comarison-toggle-button-in-left";
    offState = "recon-comarison-toggle-button-out-right";
  } else {
    onState = "recon-comarison-toggle-button-out-left";
    offState = "recon-comarison-toggle-button-in-right";
  }

  return (
    <div className="recon-comparison-toggle">
      <button
        className={onState}
        onClick={() => {
          setState("STAGE");
        }}
      >
        <h1 className="display-4">{translate("Stages")}</h1>
      </button>
      <button
        className={offState}
        onClick={() => {
          setState("RELEASE");
        }}
      >
        <h1 className="display-4">{translate("Releases")}</h1>
      </button>
    </div>
  );
}
