import { formatNumber } from "./Benchmark.functions";
import PropTypes from "prop-types";

MultiValueRow.propTypes = {
  itemData: PropTypes.object,
  keys: PropTypes.array,
};

export function MultiValueRow(props) {
  const { itemData } = props;
  const { keys } = props;

  const row = [];
  Object.entries(itemData).forEach(([key, value]) => {
    if (!keys.includes(key)) return;
    row.push(
      <div className={setClassForColumns(key)}>
        {setFormatForCell(key, value)}
      </div>
    );
  });

  return (
    <div className="pctm-container-horizontal pctm-container-matrix-horizontal">
      <div className="pctm-row pctm-matrix-row">{row}</div>
    </div>
  );
}

function setClassForColumns(key) {
  switch (key) {
    case "metric":
      return "pctm-metrix-cell pctm-value-cell-tex";
    case "code":
    case "unit":
      return "pctm-metrix-cell center-aligned-text";
    case "quantity":
      return "pctm-metrix-cell pctm-value-cell-number";
    default:
      return "pctm-metrix-cell pctm-value-cell-tex";
  }
}
function setFormatForCell(key, value) {
  switch (key) {
    case "quantity":
      return formatNumber("number", value);
    case "metric":
    case "code":
    case "unit":
    default:
      return value;
  }
}
