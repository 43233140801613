import React, { useState } from "react";
import { EditCategoryItemModal } from "./CbsCategoriesModal";
import Modal from "../modal/Modal";
import { addCategoryItem, editCategoryItem } from "../../api/cbs/CbsAPI";
import isEmpty from "../../validation/is-empty";

import "./CbsCategories.css";

export function CbsCategories(props) {
  const { selectedCategory } = props;
  const { selectedElements } = props;
  const { setSelectedElements } = props;
  const { cbs } = props;

  let table = [];
  selectedCategory?.categoryItems?.forEach((categoryItem) => {
    table.push(
      <CategoryItem
        id={categoryItem.id}
        key={categoryItem.id}
        categoryItem={categoryItem}
        cbs={cbs}
        selectedCategory={selectedCategory}
        selectedElements={selectedElements}
        setSelectedElements={setSelectedElements}
      ></CategoryItem>
    );
  });
  return (
    <div className="cbs-category">
      {table}
      <AddCategoryItemButton cbs={cbs} selectedCategory={selectedCategory} />
    </div>
  );
}

function CategoryItem(props) {
  const { categoryItem } = props;
  const { cbs } = props;
  const { selectedCategory } = props;
  const { selectedElements, setSelectedElements } = props;
  const { id } = props;

  const [modal, setModal] = useState(false);

  let table = [];
  let count = 0;
  categoryItem?.codes?.forEach((code) => {
    table.push(
      <CategoryItemCode
        key={count}
        cbs={cbs}
        selectedCategory={selectedCategory}
        categoryItem={categoryItem}
        code={code}
        colour={categoryItem.colour}
      />
    );
    count++;
  });

  table.push(
    <CategoryItemAddCodesButton
      key={count}
      cbs={cbs}
      categoryItem={categoryItem}
      selectedCategory={selectedCategory}
      selectedElements={selectedElements}
      setSelectedElements={setSelectedElements}
    />
  );

  return (
    <div>
      <div className="cbs-categoryitem">
        <button
          id={`cbs-categoryitem-${id}`}
          className="cbs-categoryitem-details"
          onClick={() => {
            setModal(true);
          }}
        >
          <div className="cbs-categoryitem-description">
            {categoryItem.description}
          </div>
        </button>

        <div className="cbs-categoryitem-codes">{table}</div>
      </div>
      <Modal
        // Modal Props
        title={"Category Item"}
        Component={EditCategoryItemModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        categoryItem={categoryItem}
        selectedCategory={selectedCategory}
        cbs={cbs}
      />
    </div>
  );
}

function AddCategoryItemButton(props) {
  const { cbs } = props;
  const { selectedCategory } = props;

  if (isEmpty(cbs?.data)) {
    return null;
  }

  if (selectedCategory?.isReadOnly) {
    return null;
  }

  if (cbs?.data?.isPublished) {
    return null;
  }

  return (
    <button
      id="cbs-add-category-item-button"
      className="general-upload-button"
      onClick={async () => {
        const cbsId = cbs?.data?.id;
        const categoryId = selectedCategory?.id;
        const typeCode = cbs?.data?.typeCode;

        let result = await addCategoryItem(cbsId, categoryId);
        if (result.success) {
          cbs.set(await cbs.get(typeCode));
        } else {
          console.log(result.message);
        }
      }}
    >
      Add New Category
    </button>
  );
}

function CategoryItemAddCodesButton(props) {
  const { cbs } = props;
  const { selectedCategory } = props;
  const { selectedElements, setSelectedElements } = props;
  const { categoryItem } = props;

  if (isEmpty(selectedElements)) {
    return null;
  }

  return (
    <button
      id="cbs-category-item-add-codes-button"
      className="cbs-button"
      onClick={async () => {
        const cbsId = cbs?.data?.id;
        const categoryId = selectedCategory?.id;
        const categoryItemId = categoryItem?.id;
        const categoryItemDescription = categoryItem?.description;
        const categoryItemCodesAdded = selectedElements;
        const categoryItemCodesRemoved = [];

        const typeCode = cbs?.data?.typeCode;

        let result = await editCategoryItem(
          cbsId,
          categoryId,
          categoryItemId,
          categoryItemDescription,
          categoryItemCodesAdded,
          categoryItemCodesRemoved
        );
        if (result.success) {
          setSelectedElements([]);
          cbs.set(await cbs.get(typeCode));
        } else {
          cbs.set(await cbs.get(typeCode));
        }
      }}
    >
      <i className="fas fa-plus"></i>
    </button>
  );
}

function CategoryItemCode(props) {
  const { code, colour } = props;
  const { cbs } = props;
  const { selectedCategory } = props;
  const { categoryItem } = props;

  return (
    <button
      id={`cbs-category-item-code`}
      className="cbs-categoryitem-code"
      style={{ backgroundColor: colour }}
      onClick={async () => {
        if (cbs?.data?.isPublished) {
          return;
        }
        const cbsId = cbs?.data?.id;
        const categoryId = selectedCategory?.id;
        const categoryItemId = categoryItem?.id;
        const categoryItemDescription = categoryItem?.description;
        const categoryItemCodesAdded = [];
        const categoryItemCodesRemoved = [code];

        const typeCode = cbs?.data?.typeCode;

        let result = await editCategoryItem(
          cbsId,
          categoryId,
          categoryItemId,
          categoryItemDescription,
          categoryItemCodesAdded,
          categoryItemCodesRemoved
        );
        if (result.success) {
          cbs.set(await cbs.get(typeCode));
        } else {
          console.log(result.message);
        }
      }}
    >
      {code}
    </button>
  );
}
