// React
import React from "react";

// Components
import PortfolioDashboardTitle from "./PortfolioDashboardTitle";
import PortfolioMetrics from "../portfolio-metrics/PortfolioMetrics";

import Dashboard from "../dashboard/Dashboard";
import ProjectsList from "../portfolio-dashboard-components/ProjectsList";
import PortfolioFiltersWarning from "../portfolio-projects/PortfolioFilterWarning";
import PortfolioTable from "../portfolio-dashboard-components/PortfolioTable";
import TotalValueChart from "../portfolio-dashboard-components/TotalValueChart";
import PortfolioCostCategories from "../portfolio-dashboard-components/CostCategories";
import PortfolioComparisonChart from "../portfolio-dashboard-components/PortfolioComparisonChart";
import CostVsBudget from "../charts/CostVsBudget";
import Cashflow from "../portfolio-dashboard-components/PortfolioCashflow";
import Spinner from "../common/Spinner";

// Table Columns
import { riskColumns, decisionColumns } from "./PortfolioDashboardTableColumns";

// Functions
import { processPortfolioDashboard } from "./PortfolioDashboard.helpers";
// Export function import exportJSON from "../../utils/exportJSON";

export default function PortfolioDashboard(props) {
  const { selectedPortfolio } = props;
  const { portfolioId } = props;
  const {
    projectsLoading,
    projectsCostPlanningLoading,
    projectsPostContractLoading,
  } = props;
  const { filteredProjects } = props;
  const { showDashboard } = props;
  const { setShowDashboard } = props;
  const { dashboardSettings } = props;
  const { portfolioFilters } = props;
  const { setPortfolioFilters } = props;
  const { TPI } = props;

  if (
    projectsLoading ||
    projectsCostPlanningLoading ||
    projectsPostContractLoading
  ) {
    return (
      <div className="portfolio-loading">
        <Spinner />
      </div>
    );
  }

  if (portfolioId === "all-projects") {
    return <div>Please select a Portfolio</div>;
  }

  // Calculate Metrics across the Portfolio
  const portfolioDashboard = processPortfolioDashboard(filteredProjects, TPI);

  // Dashboard IDs
  const PROJECT_LIST = "PROJECT_LIST";
  const COST_SUMMARY = "COST_SUMMARY";
  const PORTFOLIO_COST_SUMMARY = "PORTFOLIO_COST_SUMMARY";
  const RISK_TABLE = "RISK_TABLE";
  const DECISION_TABLE = "DECISION_TABLE";
  const PORTFOLIO_BUDGET = "PORTFOLIO_BUDGET";
  const TOTALS_CHART = "TOTALS_CHART";
  const COST_CATEGORIES = "COST_CATEGORIES";
  const BENCHMARK_COMPARISON = "BENCHMARK_COMPARISON";
  const PROGRESS_COMPARISON = "PROGRESS_COMPARISON";
  const GROSS_INTERNAL_AREA = "GROSS_INTERNAL_AREA";
  const SERVICES_METRICS = "SERVICES_METRICS";
  const CASHFLOW = "CASHFLOW";

  let display = [
    <ProjectsList
      title={"Project List"}
      dashboardID={PROJECT_LIST}
      projects={filteredProjects}
    />,
    <Cashflow title={"Portfolio Cashflow"} dashboardID={CASHFLOW} />,
    <CostVsBudget
      key={COST_SUMMARY}
      title={"Cost Planning Cost Summary"}
      dashboardID={COST_SUMMARY}
      firstValue={portfolioDashboard.budget.costPlanningBudget}
      secondValue={portfolioDashboard.totals.costPlanningCost}
      secondText={"Anticipated Cost (Excl. Tax)"}
      firstText={"Approved Cost Planning Total Budget"}
      differenceText={"Headroom to Total Budget"}
      differenceValue={
        portfolioDashboard?.budget?.costPlanningBudget -
        portfolioDashboard?.totals?.costPlanningCost
      }
      isApproved={true}
    />,
    <CostVsBudget
      key={PORTFOLIO_COST_SUMMARY}
      title={"Portfolio Cost Summary"}
      dashboardID={PORTFOLIO_COST_SUMMARY}
      firstValue={portfolioDashboard.budget.portfolioBudget}
      secondValue={portfolioDashboard.totals.portfolioCost}
      secondText={"Anticipated Cost (Excl. Tax)"}
      firstText={"Approved Portfolio Total Budget"}
      differenceText={"Headroom to Total Budget"}
      differenceValue={
        portfolioDashboard?.budget?.portfolioBudget -
        portfolioDashboard?.totals?.portfolioCost
      }
      isApproved={true}
    />,
    <PortfolioCostCategories
      title={"Project Costs at Cost Planning Phase by Cost Category"}
      costCategories={portfolioDashboard.costCategories}
      dashboardID={COST_CATEGORIES}
    />,
    <PortfolioTable
      title={"Portfolio Risks"}
      dashboardID={RISK_TABLE}
      columns={riskColumns}
      data={portfolioDashboard.risks}
    />,
    <PortfolioTable
      title={"Portfolio Decisions"}
      dashboardID={DECISION_TABLE}
      columns={decisionColumns}
      data={portfolioDashboard.decisions}
    />,
    <TotalValueChart
      title={"Aggregated Project Value by Project Phase"}
      dashboardID={TOTALS_CHART}
      graphData={portfolioDashboard.totals.graphData}
      graphLabels={portfolioDashboard.totals.graphLabels}
    />,
    // <PortfolioBudget
    //   title={"Portfolio Total Budget"}
    //   dashboardID={PORTFOLIO_BUDGET}
    //   data={portfolioDashboard.budget.graphData}
    //   width={200}
    //   height={200}
    //   eRadius={90}
    //   iRadius={70}
    //   lWidth={270}
    //   lHeight={150}
    // />,
    <PortfolioComparisonChart
      title="Progress Comparison"
      dashboardID={PROGRESS_COMPARISON}
      graphData={portfolioDashboard.postContract.graphData}
      graphLabels={portfolioDashboard.postContract.graphLabels}
      unit={"%"}
      max={100}
    />,
    <PortfolioComparisonChart
      title="Benchmark Comparison"
      dashboardID={BENCHMARK_COMPARISON}
      graphData={portfolioDashboard.benchmarking.graphData}
      graphLabels={portfolioDashboard.benchmarking.graphLabels}
      unit={""}
    />,
    <PortfolioComparisonChart
      title="Gross Internal Area by Project"
      dashboardID={GROSS_INTERNAL_AREA}
      graphData={portfolioDashboard.metrics.projectAreasGraphData}
      graphLabels={portfolioDashboard.metrics.projectAreasGraphLabels}
      unit={" m²"}
    />,
    <PortfolioComparisonChart
      key={SERVICES_METRICS}
      title="Engineering Services Cost / m2"
      dashboardID={SERVICES_METRICS}
      graphData={portfolioDashboard.metrics.servicesGraphData}
      graphLabels={portfolioDashboard.metrics.servicesGraphLabels}
      unit={" / m²"}
    />,
  ];

  // Default Layout
  const column1Set = [
    PORTFOLIO_COST_SUMMARY,
    COST_SUMMARY,
    PORTFOLIO_BUDGET,
    PROJECT_LIST,
    TOTALS_CHART,
    COST_CATEGORIES,
  ];
  const column2Set = [RISK_TABLE, BENCHMARK_COMPARISON, PROGRESS_COMPARISON];
  const column3Set = [
    CASHFLOW,
    DECISION_TABLE,
    GROSS_INTERNAL_AREA,
    SERVICES_METRICS,
  ];
  const column4Set = [
    PORTFOLIO_COST_SUMMARY,
    CASHFLOW,
    COST_SUMMARY,
    PORTFOLIO_BUDGET,
    TOTALS_CHART,
    COST_CATEGORIES,
    BENCHMARK_COMPARISON,
    PROGRESS_COMPARISON,
    RISK_TABLE,
    DECISION_TABLE,
    GROSS_INTERNAL_AREA,
    SERVICES_METRICS,
    PROJECT_LIST,
  ];

  if (dashboardSettings.loading) {
    return null;
  }

  return (
    <div className="portfolio-projects-column">
      <PortfolioFiltersWarning
        portfolioFilters={portfolioFilters}
        setPortfolioFilters={setPortfolioFilters}
      />
      <PortfolioDashboardTitle selectedPortfolio={selectedPortfolio} />
      <PortfolioMetrics portfolioDashboard={portfolioDashboard} />
      <Dashboard
        display={display}
        column1Set={column1Set}
        column2Set={column2Set}
        column3Set={column3Set}
        column4Set={column4Set}
        project={selectedPortfolio}
        // Dashboard Settings
        showDashboard={showDashboard}
        setShowDashboard={setShowDashboard}
        dashboardSettings={dashboardSettings}
        dashboardType={"PORTFOLIO"}
      />
    </div>
  );
}
