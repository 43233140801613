import React from "react";

import isEmpty from "../../validation/is-empty";
import { Donut, Legend } from "britecharts-react";
import { comma } from "../../helpers/common.functions";

import "../project-dashboard-cost-planning/PDCPDonutChart.css";

export default function PostContractDonutChart(props) {
  const { data } = props;
  const { width, height } = props;
  const { eRadius, iRadius } = props;
  const { lHeight, lWidth } = props;

  if (isEmpty(data)) {
    return null;
  }

  const maxObject = data.reduce((prev, current) => {
    return prev.value > current.value ? prev : current;
  });

  let ID = 0;

  if (!isEmpty(maxObject)) {
    ID = maxObject.id;
  }

  let heritage = "rgba(228, 97, 15, 1)";
  let aqua = "rgba(50, 98, 149, 1)";
  let collaboration1 = "rgba(85, 87, 90, 1)";
  let heritage2 = "rgba(228, 97, 15, 0.7)";
  let aqua2 = "rgba(50, 98, 149, 0.7)";
  let collaboration12 = "rgba(85, 87, 90, 0.7)";

  let colorSchema = [
    collaboration1,
    heritage,
    aqua,
    collaboration12,
    aqua2,
    heritage2,
  ];

  let margin = {
    left: -40,
    right: 10,
    top: 5,
    bottom: 5,
  };

  return (
    <div className="pdcp-donut-chart-container">
      <Donut
        isAnimated={false}
        colorSchema={colorSchema || null}
        data={data}
        width={width || 425}
        height={height || 200}
        externalRadius={eRadius || 90}
        internalRadius={iRadius || 70}
        highlightSliceById={ID}
        hasFixedHighlightedSlice={true}
      />
      <div className="pdcp-donut-chart-legend">
        <Legend
          colorSchema={colorSchema || null}
          data={data}
          height={lHeight || 120}
          width={lWidth || 365}
          numberFormat={","}
          highlightEntryById={ID}
          margin={margin || null}
        />
        {<GraphTotal data={data} />}
      </div>
    </div>
  );
}

function GraphTotal(props) {
  const { data } = props;

  let value = calculateTotal(data);

  return (
    <div className="pdcp-donut-chart-total">
      <div className="pdcp-donut-chart-total-label">
        <b>{"Total"}</b>
      </div>
      <div className="pdcp-donut-chart-total-value">
        <b>{comma(value)}</b>
      </div>
    </div>
  );
}

function calculateTotal(selection) {
  let sum = 0;
  selection.forEach((item) => {
    sum += item.value;
  });
  return Math.round(sum);
}
