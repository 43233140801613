import React from "react";

import EstimateBreakdownCommentModal from "../estimate-breakdown/EstimateBreakdownCommentModal";

import { comma, commaToFixed } from "../../helpers/common.functions";
import EstimateBreakdownComment from "../estimate-breakdown/EstimateBreakdownComment";

export default function EstimateBreakdownRow(props) {
  const { row } = props;
  const { bRow } = props;
  const { search } = props;
  const { project } = props;
  const { projectID } = props;
  const { CPs } = props;
  const { enableLineRefs } = props;
  const { filtered, notificationsOnly = false } = props;
  const [showComment, setShowComment] = React.useState(true);

  if (!bRow.description.toLowerCase().includes(search.toLowerCase())) {
    return null;
  }

  let { rowType, descriptionType } = selectRowType(
    bRow,
    enableLineRefs,
    filtered
  );

  // if notificationsOnly = true, skip returning breakdown non-notification rows
  if (notificationsOnly && !bRow.notify_estimate_comment) {
    return null;
  }

  return (
    <div key={bRow.id} className="estimate-breakdown-row-wrapper">
      <div>
        <RowData
          descriptionType={descriptionType}
          rowType={rowType}
          bRow={bRow}
          enableLineRefs={enableLineRefs}
          filtered={filtered}
          showComment={showComment}
          setShowComment={setShowComment}
        />
        {!showComment && (
          <div className={rowType}>
            <EstimateBreakdownComment
              CPs={CPs}
              breakdown={bRow}
              projectID={projectID}
              project={project}
              rowID={row.id}
              show={!showComment}
              filtered={filtered}
            />
          </div>
        )}
      </div>
      <EstimateBreakdownCommentModal
        breakdown={bRow}
        projectID={projectID}
        rowType={rowType}
        CPs={CPs}
        project={project}
        rowID={row.id}
        filtered={filtered}
      />
    </div>
  );
}

function DisplayRefCell(props) {
  const { enableLineRefs } = props;
  const { bSection } = props;

  if (enableLineRefs) {
    return <div className="ebt-section-cell">{bSection}</div>;
  } else {
    return null;
  }
}

function isNoteRow(bRow) {
  return (
    (bRow.quantity === 0 || bRow.quantity === null) &&
    (bRow.rate === 0 || bRow.rate === null) &&
    (bRow.subtotal === 0 || bRow.subtotal === null)
  );
}

function selectRowType(bRow, enableLineRefs, filtered) {
  let rowType = "ebt-row";
  let descriptionType = "ebt-description-cell";

  // Row Type
  if (isNoteRow(bRow)) {
    rowType = "ebt-note-row";
  }

  if (bRow.formatting) {
    if (bRow.formatting.toLowerCase() === "head1") {
      rowType = "ebt-row-head1";
    }

    if (bRow.formatting.toLowerCase() === "head2") {
      rowType = "ebt-row-head2";
    }

    if (bRow.formatting.toLowerCase() === "item") {
      rowType = "ebt-row";
    }
  }

  if (enableLineRefs) {
    descriptionType = "ebt-description-cell-ref";
  }

  if (filtered !== undefined) {
    descriptionType = "ebt-description-cell-filtered";
    if (rowType === "ebt-row") {
      rowType = "ebt-row-filtered";
    }
  }

  return {
    rowType,
    descriptionType,
  };
}

function RowData(props) {
  const { rowType } = props;
  const { enableLineRefs } = props;
  const { bRow } = props;
  const { descriptionType } = props;
  const { filtered } = props;
  const { showComment, setShowComment } = props;

  return (
    <div className={rowType}>
      <DisplayRefCell
        enableLineRefs={enableLineRefs}
        bSection={bRow.breakdown_section}
      />
      <div className={descriptionType}>
        {filtered === undefined ? (
          bRow.description
        ) : (
          <button
            onClick={() => {
              setShowComment(!showComment);
            }}
            className="estimate-breakdown-plusminus-filtered"
          >
            {showComment ? (
              <i className="far fa-plus-square"></i>
            ) : (
              <i className="far fa-minus-square"></i>
            )}
          </button>
        )}
        {filtered !== undefined ? bRow.description : ""}
      </div>
      <div className="ebt-quantity-cell ">
        {bRow.quantity ? comma(bRow.quantity) : null}
      </div>
      <div className="ebt-unit-cell">{bRow.unit_of_measure}</div>
      <div className="ebt-rate-cell ">
        {bRow.rate ? commaToFixed(bRow.rate) : null}
      </div>
      <div className="ebt-subtotal-cell">
        {bRow.subtotal ? comma(Math.round(bRow.subtotal)) : null}
      </div>
    </div>
  );
}
