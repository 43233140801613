import { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";

// API
import { pc2APIs } from "../api-endpoints/PostContract2.0Endpoints";

export function usePostContractReports(projectId, packageId) {
  const [loading, setLoading] = useState(false);
  const [postContractReports, setPostContractReports] = useState([]);

  // Get the Post Contract Section
  const URL = window.location.href;
  const pagename = URL.substring(URL.lastIndexOf("/") + 1);

  useEffect(() => {
    // Only make the request inside Post Contract
    if (
      window.location.href.includes("post-contract") ||
      window.location.href.includes("dashboard")
    ) {
      if (!isEmpty(packageId) && !isEmpty(pagename)) {
        setLoading(true);
        getPostContractReports(projectId, packageId, pagename)
          .then((data) => {
            setPostContractReports(data);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }, [projectId, packageId, pagename]);

  return {
    postContractReports: {
      data: postContractReports,
      loading: loading,
      get: getPostContractReports,
      set: setPostContractReports,
    },
  };
}

export async function getPostContractReports(projectId, packageId, pagename) {
  let sheetName = pagename;
  if (pagename === "dashboard") {
    sheetName = "Cost Report";
  }

  if (pagename === "Commentary") {
    sheetName = "Cost Report";
  }

  if (pagename === "Cashflow") {
    sheetName = "Progress Claim";
  }

  // POST CONTRACT API
  const url =
    pc2APIs().read_reports +
    "?projectId=" +
    projectId +
    "&packageId=" +
    packageId +
    "&sheetName=" +
    sheetName;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
    //Add new get here
  } catch (e) {
    console.log("getPostContractReports api error");
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log("getPostContractReports response error");
  }
  return response;
}
