import React, { useState } from "react";

import Report from "./Report";

import "./ReportModal.css";
import { useReportStatus } from "../../api/reports/GenerateReportAPI.js";

import {
  ReportGenerateButton,
  ReportImportButton,
  ReportImportStatus,
} from "./ReportModalComponents";

const ReportModal = (props) => {
  const { CP, project, costReport, projectPhase, type } = props;

  // PowerBI Report
  const [reportDetails, setReportDetails] = useState({});
  // PowerBI Report Loading
  const [reportLoading, setReportLoading] = useState(false);
  // Params to send to the report
  const reportParams = {
    CP: CP,
    project: project,
    costReport: costReport,
    projectPhase: projectPhase,
  };

  // Status of the import data for a given report
  const { reportStatus } = useReportStatus(reportParams);

  return (
    <div className="edit-stages-container">
      <div className="report-export-introduction">
        You can create a report of this Project's currently selected {type} and
        export it into Microsoft Office and PDF file formats.
      </div>
      <ol>
        <li>
          Start by clicking{" "}
          <div className="export-report-export-text">Create New Report</div> to
          create a report of the currently selected {type}.
        </li>
        <li>
          Once a report has been completed, click{" "}
          <div className="export-report-view-text">Open Report</div> to view the
          report and available options.
        </li>
        <li>
          When the report opens, you can perform multiple actions such as
          printing or saving into various file formats by opening the report{" "}
          <b>Menu</b>.
        </li>
        <li>
          The <div className="export-report-date-text">Report Timestamp</div> is
          the date and time of the last report and reflects the age of the data
          in the report.
        </li>
        <li>
          If a previous report exists you can open it immediately, but it may be
          out of date if the Project was updated after the report was created.
        </li>
        <li>
          Deleting anything from a {type} will cause any corresponding reports
          to be deleted. Please simply{" "}
          <div className="export-report-export-text">Create New Report</div>{" "}
          again.
        </li>
      </ol>

      <div className="export-embedded-report-input-row">
        <ReportImportButton
          reportParams={reportParams}
          reportStatus={reportStatus}
        />
        <ReportImportStatus
          reportStatus={reportStatus}
          reportParams={reportParams}
        />
        <ReportGenerateButton
          reportParams={reportParams}
          reportStatus={reportStatus}
          setReportLoading={setReportLoading}
          setReportDetails={setReportDetails}
        />
      </div>
      <div className="report-container">
        <Report
          reportStatus={reportStatus}
          reportDetails={reportDetails}
          reportLoading={reportLoading}
        />
      </div>
    </div>
  );
};

export default ReportModal;
