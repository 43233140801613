import React from "react";
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import "./OptionsAnalysisStageSelector.css";

export default function OptionsAnalysisStageSelector(props) {
  const { options } = props;
  const { activeStage, setActiveStage } = props;

  if (isEmpty(options)) {
    return null;
  }
  // Cost Planning Page
  if (!window.location.href.includes("optioneering")) {
    return null;
  }

  // Display the stage boxes
  const displayStages = options.optionsAnalysisStages.map((stage) => {
    const boxType =
      activeStage?.id === stage.id
        ? "options-analysis-stage-selector-current-stage-box"
        : "options-analysis-stage-selector-box";

    return (
      <button
        key={stage.id}
        className={boxType}
        onClick={() => setActiveStage(stage)}
      >
        {stage.code}
      </button>
    );
  });

  // Hide the stage selector if there are no stages to display
  if (isEmpty(displayStages)) {
    return null;
  }

  return (
    <div className="options-analysis-stage-selector-container">
      <div className="options-analysis-stage-selector-label">
        {translate("Analyses")}
      </div>
      {displayStages}
    </div>
  );
}
