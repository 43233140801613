// React
import React from "react";
import PropTypes from "prop-types";

// Page
import Dashboard from "../dashboard/Dashboard";
import PostContractComponent from "./PostContractComponent";
import PostContractCommentary from "./PostContractCommentary";
import DecisionsAdvice from "../dashboard-components/DecisionsAdvice";
import DashboardRisks from "../dashboard-components/RisksAdvice";

// Styles
import "./PostContractCostReport.css";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

CostReport.propTypes = {
  project: PropTypes.object,
  postContract: PropTypes.object,
  selectedReport: PropTypes.object,
  dashboardSettings: PropTypes.object,
  showCRPanel: PropTypes.bool,
  setShowCRPanel: PropTypes.func,
};

export default function CostReport(props) {
  const { project } = props;

  const { postContract } = props;
  const { selectedReport } = props;

  const { dashboardSettings } = props;
  const { showCRPanel, setShowCRPanel } = props;

  if (isEmpty(selectedReport)) {
    return null;
  }
  // Create Dashboard IDs

  // Cost Report
  const BUDGET = "BUDGETSUMMARY";
  const HDRMBUDGET = "HDRMBUDGET";
  const FORECASTCOST = "FORECASTCOST";
  const CONTINGENCYBALANCE = "CONTINGENCYBALANCE";
  const CONTINGENCYBALANCE_BAR = "CONTINGENCYBALANCE_BAR";
  const CONTINGENCYBALANCE_CHART = "CONTINGENCYBALANCE_CHART";
  const DETAILS = "DETAILSPANEL";

  // Variations
  const VARI = "VARIATIONPANEL";
  const CRITICALVARIATIONS = "CRITICALVARIATIONS";
  const CHANGETYPE = "CHANGETYPE";
  const CHANGETYPE_TABLE = "CHANGETYPE_TABLE";

  // History
  const TRENDCHART = "TRENDCHART";
  const COMPARISONCHART = "COMPARISONCHART";
  const COMPARISONTABLE = "COMPARISONTABLE";

  // Commentary
  const PCCOMMENTARY = "PCCOMMENTARY";
  const DECISIONS = "DECISIONS";
  const RISKS = "RISKS";
  const NOTES = "NOTES";
  const GLOSSARY = "GLOSSARY";
  const EXCLUSIONS = "EXCLUSIONS";

  // Progress Claim
  const TRACKER = "TRACKER";
  const RECOMMENDATION = "RECOMMENDATION";
  const TIMEBAR = "TIMEBARGRAPH";

  // Provisional Sums
  const CRITICALPROVISIONALSUMS = "CRITICALPROVISIONALSUMS";
  const PROVISIONALSUMSDONUT = "PROVISIONALSUMSDONUT";
  const PSSUMMARY = "PSSUMMARY";
  const PSSTATUS = "PSSTATUS";

  // Client Directs
  const CDCOSTSUMMARY = "CDCOSTSUMMARY";

  // Risk Register
  const RRTYPEGRAPH = "RRTYPEGRAPH";
  const RRTYPETABLE = "RRTYPETABLE";
  const CRITICALRR = "CRITICALRR";

  //Retention
  const RETENTIONANALYSIS = "RETENTION";

  // The Components
  const display = [
    <PostContractComponent
      key={RETENTIONANALYSIS}
      dashboardID={RETENTIONANALYSIS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Retention Analysis Table"}
      title={"Retention Analysis"}
    />,

    <PostContractComponent
      key={CONTINGENCYBALANCE_CHART}
      dashboardID={CONTINGENCYBALANCE_CHART}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"HorizontalBarCharts"}
      sectionType={"Cost Report"}
      componentName={"Balance of Contingency Chart"}
      title={"Balance of Contingency"}
    />,

    <PostContractComponent
      key={RRTYPEGRAPH}
      dashboardID={RRTYPEGRAPH}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Cost Report"}
      componentName={"Risk Types Chart"}
      title={"Risk Types"}
    />,

    <PostContractComponent
      key={RRTYPETABLE}
      dashboardID={RRTYPETABLE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Risk Types Table"}
      title={"Risk Types"}
    />,

    <PostContractComponent
      key={CRITICALVARIATIONS}
      dashboardID={CRITICALVARIATIONS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Critical Con Costs Variations Table"}
      title={"Critical Variations"}
    />,

    <PostContractComponent
      key={CRITICALPROVISIONALSUMS}
      dashboardID={CRITICALPROVISIONALSUMS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Critical Prov Sums Table"}
      title={"Critical Provisional Sums"}
    />,

    <PostContractComponent
      key={CRITICALRR}
      dashboardID={CRITICALRR}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Critical Risk Register Table"}
      title={"Critical Risks"}
    />,

    <PostContractComponent
      key={PSSTATUS}
      dashboardID={PSSTATUS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Provisional Sums Status Table"}
      title={"Provisional Sums Status"}
    />,

    <PostContractComponent
      key={COMPARISONCHART}
      dashboardID={COMPARISONCHART}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"BarCharts"}
      sectionType={"Cost Report"}
      componentName={"Cost Report Comparison Chart"}
      title={"Cost Report Comparison"}
    />,

    <PostContractComponent
      key={COMPARISONTABLE}
      dashboardID={COMPARISONTABLE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Cost Report Comparison Table"}
      title={"Cost Report Comparison Table"}
    />,
    <PostContractComponent
      key={BUDGET}
      dashboardID={BUDGET}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Budget Summary Table"}
      title={"Budget Summary"}
    />,

    <PostContractComponent
      key={FORECASTCOST}
      dashboardID={FORECASTCOST}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Forecast Cost Summary Table"}
      title={"Forecast Cost Summary"}
    />,
    <PostContractComponent
      key={CONTINGENCYBALANCE}
      dashboardID={CONTINGENCYBALANCE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Headroom to Budget Table"}
      title={"Headroom to Budget Table"}
    />,

    <PostContractComponent
      key={TRENDCHART}
      dashboardID={TRENDCHART}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"LineCharts"}
      sectionType={"Cost Report"}
      componentName={"Cost Report Trends Chart"}
      title={"Cost Report Trends"}
    />,

    <PostContractComponent
      key={DETAILS}
      dashboardID={DETAILS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"ReportDetailsCharts"}
      sectionType={"Cost Report"}
      componentName={"Cost Report Details Chart"}
      title={"Cost Report Details"}
    />,

    <PostContractComponent
      key={RECOMMENDATION}
      dashboardID={RECOMMENDATION}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Progress Payment Recommendation Table"}
      title={"Progress Payment Recommendation"}
    />,
    <PostContractComponent
      key={TIMEBAR}
      dashboardID={TIMEBAR}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TimeBarComponents"}
      sectionType={"Cost Report"}
      componentName={"Time Elapsed Bar"}
      title={"Time Elapsed"}
    />,

    <PostContractComponent
      key={TRACKER}
      dashboardID={TRACKER}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"HorizontalBarCharts"}
      sectionType={"Cost Report"}
      componentName={"Contract Tracker Chart"}
      title={"Contract Tracker"}
    />,

    <PostContractComponent
      key={VARI}
      dashboardID={VARI}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Variation Status Table"}
      title={"Variation Status"}
    />,

    <PostContractComponent
      key={HDRMBUDGET}
      dashboardID={HDRMBUDGET}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"CostBudgetCharts"}
      sectionType={"Cost Report"}
      componentName={"Headroom to Budget Chart"}
      title={"Headroom to Budget"}
    />,

    <PostContractComponent
      key={RISKS}
      dashboardID={RISKS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Risks / Opportunities"}
      title={"Risks / Opportunities"}
    />,

    <PostContractComponent
      key={NOTES}
      dashboardID={NOTES}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Notes and Clarifications"}
      title={"Notes and Clarifications"}
    />,
    <PostContractComponent
      key={EXCLUSIONS}
      dashboardID={EXCLUSIONS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Exclusions"}
      title={"Exclusions"}
    />,

    <PostContractComponent
      key={CHANGETYPE}
      dashboardID={CHANGETYPE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Cost Report"}
      componentName={"Change Type Summary Chart"}
      title={"Change Type Summary"}
    />,

    <PostContractComponent
      key={CHANGETYPE_TABLE}
      dashboardID={CHANGETYPE_TABLE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Change Type Summary Table"}
      title={"Change Type Summary Table"}
    />,
    <PostContractComponent
      key={PSSUMMARY}
      dashboardID={PSSUMMARY}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"CostBudgetCharts"}
      sectionType={"Cost Report"}
      componentName={"Provisional Sums Summary"}
      title={"Provisional Sums Summary"}
    />,
    <PostContractComponent
      key={PROVISIONALSUMSDONUT}
      dashboardID={PROVISIONALSUMSDONUT}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Cost Report"}
      componentName={"Provisional Sums Summary Chart"}
      title={"Provisional Sums Summary"}
    />,

    <PostContractComponent
      key={CDCOSTSUMMARY}
      dashboardID={CDCOSTSUMMARY}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"CostBudgetCharts"}
      sectionType={"Cost Report"}
      componentName={"Client Direct Costs Summary Chart"}
      title={"Client Direct Costs Summary"}
    />,
    <DecisionsAdvice
      title={"Contractual Glossary"}
      key={GLOSSARY}
      dashboardID={GLOSSARY}
      decisions={postContract.data[0]?.Advice.DecisionsGlossary}
      type={"glossary"}
      column1={"decisions-advice-glossary-term"}
      column2={"decisions-advice-glossary-definition"}
      isGlossary
    />,
    <DecisionsAdvice
      title={"Decisions Required"}
      key={DECISIONS}
      dashboardID={DECISIONS}
      decisions={postContract.data[0]?.Advice.DecisionsGlossary}
      type={"decision"}
      column1={"decisions-advice-decision"}
      column2={"decisions-advice-required_by"}
      isDecision
    />,
    <PostContractCommentary
      key={EXCLUSIONS}
      dashboardID={EXCLUSIONS}
      commentary={postContract.data[0]?.Advice.ExclusionsClarificationsProgress}
      type={"cost_report_exclusions"}
      title={"Exclusions"}
    />,

    <PostContractCommentary
      key={NOTES}
      dashboardID={NOTES}
      commentary={postContract.data[0]?.Advice.ExclusionsClarificationsProgress}
      type={"cost_report_clarifications"}
      title={"Notes and Clarifications"}
    />,
    <DashboardRisks
      title={translate("Risks / Opportunities")}
      key={RISKS}
      dashboardID={RISKS}
      risks={postContract.data[0]?.Advice.Risks}
    />,
  ];

  const column1Set = [
    RETENTIONANALYSIS,

    BUDGET,
    FORECASTCOST,

    CONTINGENCYBALANCE,
    CONTINGENCYBALANCE_BAR,
    CONTINGENCYBALANCE_CHART,

    CRITICALRR,
    CRITICALPROVISIONALSUMS,

    NOTES,

    GLOSSARY,
    RECOMMENDATION,

    CDCOSTSUMMARY,

    RRTYPEGRAPH,
    RRTYPETABLE,
  ];
  const column2Set = [
    DETAILS,
    VARI,
    CRITICALVARIATIONS,
    TIMEBAR,
    TRACKER,
    PSSUMMARY,
    PROVISIONALSUMSDONUT,
    PSSTATUS,
    PCCOMMENTARY,
    EXCLUSIONS,
  ];
  const column3Set = [
    TRENDCHART,
    COMPARISONCHART,
    COMPARISONTABLE,
    HDRMBUDGET,
    CHANGETYPE,
    CHANGETYPE_TABLE,
    DECISIONS,
    RISKS,
  ];

  // Master List of Components
  // (All components need to be added here for them to be selectable if removed)
  const column4Set = column1Set.concat(column2Set.concat(column3Set));

  return (
    <div>
      <PostContractComponent
        postContract={postContract}
        selectedReport={selectedReport}
        componentType={"TableComponents"}
        sectionType={"Cost Report"}
        componentName={"Cost Report Table"}
        isTable={true}
      />

      <Dashboard
        display={display}
        column1Set={column1Set}
        column2Set={column2Set}
        column3Set={column3Set}
        column4Set={column4Set}
        project={project}
        // Dashboard Settings
        showDashboard={showCRPanel}
        setShowDashboard={setShowCRPanel}
        dashboardSettings={dashboardSettings}
        dashboardType={"COST_REPORT"}
      />
    </div>
  );
}
