import React, { useState } from "react";
import { CbsName } from "./CbsAdmin.components";
import { editCategoryItem, deleteCategoryItem } from "../../api/cbs/CbsAPI";
import { AutoCloseModal } from "../../components/modal/Modal";

export function EditCategoryItemModal(props) {
  const { categoryItem } = props;
  const { selectedCategory } = props;
  const { cbs } = props;
  const { setModal } = props;

  const [description, setDescription] = useState(
    categoryItem.description || ""
  );

  const [message, setMessage] = useState("");

  if (cbs?.data?.isPublished) {
    return (
      <div className="cbs-modal">
        <div className="general-button-container">
          <div>You cannot change a Category Item for a published CBS.</div>
        </div>
      </div>
    );
  }

  if (categoryItem?.isReadOnly) {
    return (
      <div className="cbs-modal">
        <div className="general-button-container">
          <div>
            {categoryItem?.description} is required and cannot be changed.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="cbs-modal">
      <div className="general-row-container">
        <div className="cbs-input-field">Category Name</div>
      </div>
      <div className="general-row-container">
        <CbsName get={description} set={setDescription} />
      </div>
      <div className="general-row-container">{message}</div>
      <div className="general-row-container">
        <EditCategoryItemButton
          cbs={cbs}
          categoryItem={categoryItem}
          selectedCategory={selectedCategory}
          description={description}
          setMessage={setMessage}
          setModal={setModal}
        />
      </div>
      <div className="general-button-container">
        <DeleteCategoryItemButton
          cbs={cbs}
          categoryItem={categoryItem}
          selectedCategory={selectedCategory}
          setMessage={setMessage}
          setModal={setModal}
        />
      </div>
    </div>
  );
}

function EditCategoryItemButton(props) {
  const { cbs } = props;
  const { description } = props;
  const { selectedCategory } = props;
  const { categoryItem } = props;
  const { setMessage } = props;
  const { setModal } = props;

  return (
    <button
      id={"cbs-admin-category-item-modal-update-button"}
      className="general-upload-button"
      onClick={async () => {
        setMessage("Updating...");
        const cbsId = cbs?.data?.id;
        const categoryId = selectedCategory?.id;
        const categoryItemId = categoryItem?.id;
        const categoryItemDescription = description;
        const categoryItemCodesAdded = [];
        const categoryItemCodesRemoved = [];

        const typeCode = cbs?.data?.typeCode;

        let result = await editCategoryItem(
          cbsId,
          categoryId,
          categoryItemId,
          categoryItemDescription,
          categoryItemCodesAdded,
          categoryItemCodesRemoved
        );
        if (result.success) {
          setMessage(result.message);
          AutoCloseModal(setModal);
          cbs.set(await cbs.get(typeCode));
        } else {
          setMessage(result.message);
        }
      }}
    >
      Update
    </button>
  );
}

function DeleteCategoryItemButton(props) {
  const { cbs } = props;
  const { categoryItem } = props;
  const { selectedCategory } = props;
  const { setMessage } = props;
  const { setModal } = props;

  return (
    <button
      id={"cbs-admin-category-item-modal-delete-button"}
      className="general-modal-button"
      onClick={async () => {
        setMessage("Deleting...");

        const cbsId = cbs?.data?.id;
        const categoryId = selectedCategory?.id;
        const categoryItemId = categoryItem?.id;
        const typeCode = cbs?.data?.typeCode;

        let result = await deleteCategoryItem(
          cbsId,
          categoryId,
          categoryItemId
        );
        if (result.success) {
          setMessage(result.message);
          AutoCloseModal(setModal);
          cbs.set(await cbs.get(typeCode));
        } else {
          setMessage(result.message);
        }
      }}
    >
      Delete
    </button>
  );
}
