import React from "react";

import "./Modal.css";

export default function Modal(props) {
  const { Component } = props;
  const { modal, setModal } = props;
  const { title } = props;

  const showHideClassName = modal ? "modal-show" : "modal-hide";

  return (
    <div className={"modal " + showHideClassName}>
      {modal && (
        <div className="modal-display">
          <TitleBar title={title} modal={modal} setModal={setModal} />
          <Component {...props} modal={modal} setModal={setModal} />
        </div>
      )}
    </div>
  );
}

function TitleBar(props) {
  return (
    <div className="modal-top-bar">
      <Title {...props} />
      <Close {...props} />
    </div>
  );
}

function Title(props) {
  const { title } = props;
  return (
    <div className="modal-title">
      <b>{title}</b>
    </div>
  );
}

function Close(props) {
  const { setModal } = props;
  return (
    <button
      className="modal-close-button"
      onClick={() => {
        setModal(false);
      }}
    >
      <i className="fas fa-times" />
    </button>
  );
}

const MODAL_AUTO_CLOSE_TIMEOUT = 2000; // 2 seconds

export function AutoCloseModal(setModal) {
  if (setModal && typeof setModal === "function") {
    setTimeout(() => setModal(false), MODAL_AUTO_CLOSE_TIMEOUT);
  }
}
