import { detectDeployment } from "./Utilities";

export function pcAPIs() {
  const config = {
    serviceAPI: true,
    localService:
      "https://dev.cost-clarity.com/postcontract/apis/postcontractservice/v1.0",
    deployedService: "/postcontract/apis/postcontractservice/v1.0",
  };

  // Deployed Dev
  // "https://dev.cost-clarity.com/postcontract/apis/postcontractservice/v1.0"
  // "https://test.cost-clarity.com/postcontract/apis/postcontractservice/v1.0"

  // Local Dev
  //  "https://localhost:44380/v1.0"

  const D = detectDeployment(config);
  return {
    // Get Post Contract
    get_post_contract: `${D}/post-contract/get`,

    // Upload
    upload_post_contract: `${D}/post-contract/upload`,
    upload_risk_register: `${D}/post-contract-upload-risk-register/upload_post_contract_risk_register`,

    delete_risk_register: `${D}/post-contract/delete_risk_register`,

    // Save Critical Items
    save_critical_provisional_sums: `${D}/post-contract/save_critical_provisional_sums`,
    save_critical_risk_register: `${D}/post-contract/save_critical_risk_register`,
    save_critical_variations: `${D}/post-contract/save_critical_variations`,

    // Commentary

    // Projects and Portfolio
    get_projects_post_contract: `${D}/projects/get_projects_post_contract`,
    get_pc_configs: `${D}/project-post-contract/configurations`,
    update_project_features: `${D}/projects-decoupled-control/update_postcontract_features`,

    // Post Contract Details
    save_post_contract_duration: `${D}/post-contract/save_post_contract_duration`,

    // Project Admin
    delete_all_post_contract: `${D}/projects-decoupled-control/delete_post_contract`,

    // Cashflow
    save_cashflow: `${D}/post-contract/save_cashflow`,

    // System Dashboard
    get_cost_report_stats: `${D}/post-contract/get_cost_report_stats`,

    // Cost Planning Commentary
    commentary_delete: `${D}/costreport-commentary/commentary/delete`,
    commentary_edit: `${D}/costreport-commentary/commentary/edit`,
    commentary_create: `${D}/costreport-commentary/commentary/create`,
    commentary_upload_table: `${D}/costreport-commentary/commentary/upload_table`,

    // New
    update_form: `${D}/update_form`,

    // Post Contract 2.0
    get_packages: `${D}/get_packages`,
  };
}
