import React, { useState, useEffect } from "react";

import Modal from "../modal/Modal";

import "./CostPlanningSubtotalModal.css";

import {
  addSubtotal,
  removeSubtotal,
  selectCostPlan,
  saveSubtotals,
} from "./CostPlanningSubtotalModal.functions";
import { hasRoles } from "../../utils/roles";
import { translate, tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

export default function CostPlanningSubtotalModal(props) {
  const { user } = props;
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;

  const [modal, setModal] = useState(false);

  const [selectionArray, setSelectionArray] = useState([]);
  const [subtotalNames, setSubtotalNames] = useState({});
  const [GFAOverrides, setGFAOverrides] = useState({});

  const [totalName, setTotalName] = useState("");
  const [totalGFAOverride, setTotalGFAOverride] = useState("");

  // Add the estimates to the selection array
  useEffect(() => {
    if (!isEmpty(CP)) {
      setSelectionArray(selectCostPlan(CP.estimates));
      // Initialize values
      let selection = selectCostPlan(CP.estimates);
      let names = {};
      let gfas = {};
      let thisTotalName = "";
      let thisTotalGFAOverride = "";
      selection.forEach((estimate) => {
        names[estimate.id] = estimate.subtotal_name;
        gfas[estimate.id] = estimate.subtotal_gfa_override;
        thisTotalName = estimate.total_name;
        thisTotalGFAOverride = estimate.total_gfa_override || "";
      });
      setSubtotalNames(names);
      setGFAOverrides(gfas);
      setTotalName(thisTotalName);
      setTotalGFAOverride(thisTotalGFAOverride);
    }
  }, [CP]);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (isEmpty(CP)) {
    return null;
  }

  // Hide if not Summary
  let subLocation = determineSubLocation();
  if (subLocation !== "summary") {
    return null;
  }

  return (
    <div>
      <button
        className="cost-planning-subtotal-open"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Configure Totals")}
      </button>
      <Modal
        // Modal Props
        title={translate("Configure Totals")}
        Component={CostPlanningSubtotalModalComponent}
        modal={modal}
        setModal={setModal}
        // Component Props
        totalName={totalName}
        setTotalName={setTotalName}
        // Total GFA Override
        totalGFAOverride={totalGFAOverride}
        setTotalGFAOverride={setTotalGFAOverride}
        // Subtotal Names
        subtotalNames={subtotalNames}
        setSubtotalNames={setSubtotalNames}
        // Subtotal GFAs
        GFAOverrides={GFAOverrides}
        setGFAOverrides={setGFAOverrides}
        // Selected Estimates
        selectionArray={selectionArray}
        setSelectionArray={setSelectionArray}
        // Cost Plans
        CPs={CPs}
        project={project}
        projectID={project.id}
        // Modal
        setShow={setModal}
        // User
        user={user}
      />
    </div>
  );
}

function CostPlanningSubtotalModalComponent(props) {
  const { selectionArray, setSelectionArray } = props;
  const { subtotalNames, setSubtotalNames } = props;
  const { GFAOverrides, setGFAOverrides } = props;
  const { totalName, setTotalName } = props;
  const { totalGFAOverride, setTotalGFAOverride } = props;
  const { CPs } = props;
  const { projectID, project } = props;
  const { setShow } = props;

  const [error, setError] = useState({});

  const display = [];

  selectionArray.forEach((estimate, index) => {
    if (estimate.is_included) {
      display.push(
        <EstimateRow
          key={estimate.id}
          estimate={estimate}
          index={index}
          selectionArray={selectionArray}
          setSelectionArray={setSelectionArray}
          subtotalNames={subtotalNames}
          setSubtotalNames={setSubtotalNames}
          GFAOverrides={GFAOverrides}
          setGFAOverrides={setGFAOverrides}
        />
      );
    }
  });

  display.push(
    <EstimateTotalRow
      key={"estimate-total-row"}
      totalName={totalName}
      setTotalName={setTotalName}
      totalGFAOverride={totalGFAOverride}
      setTotalGFAOverride={setTotalGFAOverride}
    />
  );

  selectionArray.forEach((estimate, index) => {
    if (!estimate.is_included) {
      display.push(
        <EstimateRow
          estimate={estimate}
          index={index}
          selectionArray={selectionArray}
          setSelectionArray={setSelectionArray}
          subtotalNames={subtotalNames}
          setSubtotalNames={setSubtotalNames}
          GFAOverrides={GFAOverrides}
          setGFAOverrides={setGFAOverrides}
        />
      );
    }
  });

  return (
    <section className="cost-planning-subtotal-main">
      <div className="cost-planning-subtotal">
        <div className="cost-planning-subtotal-estimate-display">{display}</div>

        <div className="general-row-container">
          <DisplayError error={error} />
        </div>
        <div className="general-button-container">
          <button
            className="general-upload-button"
            onClick={() => {
              const data = {
                estimates: selectionArray,
                totalName,
                totalGFAOverride,
                subtotalNames,
                GFAOverrides,
                setError,
                setShow,
                CPs,
                projectID,
                project,
              };
              saveSubtotals(data);
            }}
          >
            {translate("Save")}
          </button>
        </div>
      </div>
    </section>
  );
}

function AddSubtotalButton(props) {
  const { estimate, index, selectionArray, setSelectionArray } = props;

  if (estimate.subtotal) {
    return null;
  }

  if (estimate.subtotal_below) {
    return null;
  }

  return (
    <button
      className="cost-planning-subtotal-estimate-add-button"
      onClick={() => {
        addSubtotal(index, selectionArray, setSelectionArray);
      }}
    >
      <i className="fas fa-plus" />
    </button>
  );
}

function RemoveSubtotalButton(props) {
  const { index, selectionArray, setSelectionArray } = props;

  return (
    <button
      className="cost-planning-subtotal-estimate-add-button"
      onClick={() => {
        removeSubtotal(index, selectionArray, setSelectionArray);
      }}
    >
      <i className="fas fa-minus" />
    </button>
  );
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}

function EstimateRow(props) {
  const { index } = props;
  const { estimate } = props;
  const { selectionArray, setSelectionArray } = props;
  const { subtotalNames, setSubtotalNames } = props;
  const { GFAOverrides, setGFAOverrides } = props;

  if (estimate.subtotal_below) {
    return (
      <div key={estimate.id}>
        <div className="cost-planning-subtotal-estimate-example">
          <div className="cost-planning-subtotal-estimate-bar">
            {estimate.name}
          </div>
          <AddSubtotalButton
            estimate={estimate}
            index={index}
            selectionArray={selectionArray}
            setSelectionArray={setSelectionArray}
          />
        </div>
        <div className="cost-planning-subtotal-estimate-example">
          <input
            className="cost-planning-subtotal-estimate-subtotal"
            placeholder={tranStr("Enter Subtotal Text")}
            value={subtotalNames[estimate.id] || ""}
            onChange={(e) => {
              let names = { ...subtotalNames };
              names[estimate.id] = e.target.value;
              setSubtotalNames(names);
            }}
          ></input>
          <input
            className="cost-planning-subtotal-estimate-subtotal"
            placeholder={tranStr("Enter Area Override")}
            value={GFAOverrides[estimate.id] || ""}
            onChange={(e) => {
              let gfa = e.target.value;

              let gfas = { ...GFAOverrides };
              gfas[estimate.id] = gfa;
              setGFAOverrides(gfas);
            }}
          ></input>
          <RemoveSubtotalButton
            index={index}
            selectionArray={selectionArray}
            setSelectionArray={setSelectionArray}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        key={estimate.id}
        className="cost-planning-subtotal-estimate-example"
      >
        <div className="cost-planning-subtotal-estimate-bar">
          {estimate.name}
        </div>
        <AddSubtotalButton
          estimate={estimate}
          index={index}
          selectionArray={selectionArray}
          setSelectionArray={setSelectionArray}
        />
      </div>
    );
  }
}

function EstimateTotalRow(props) {
  const { totalName, setTotalName } = props;
  const { totalGFAOverride, setTotalGFAOverride } = props;

  return (
    <div className="cost-planning-subtotal-estimate-example">
      <input
        className="cost-planning-subtotal-estimate-bar-total"
        placeholder={tranStr("Enter Total Text")}
        value={totalName}
        onChange={(e) => {
          setTotalName(e.target.value);
        }}
      ></input>
      <input
        className="cost-planning-subtotal-estimate-bar-total"
        placeholder={tranStr("Enter Total Area Override")}
        value={totalGFAOverride}
        onChange={(e) => {
          setTotalGFAOverride(e.target.value);
        }}
      ></input>
    </div>
  );
}
