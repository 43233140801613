// React
import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";

import { translate } from "../../utils/translation";

// Components
import {
  PortfolioInputSingle,
  PortfolioSelectSingle,
  PortfolioSelectSingleFilter,
} from "./PortfolioFilter.filters";

import {
  sectorList,
  subsectorList,
  procurementList,
  typeList,
  qualityList,
} from "./PortfolioFilter.functions";

import "./PortfolioFilter.css";

export default function PortfolioFilter(props) {
  const { setPageSize } = props;
  const { setPortfolioFilters } = props;
  const { pageSize } = props;
  const { showFilters, setShowFilters } = props;

  const [filters, setFilters] = useState({
    title: "",
    clientCompany: "",
    sector: "",
    subsector: "",
    projectType: "",
    projectQuality: "",
    procurementMethod: "",
    location: "",
    searchTags: "",
  });

  let contentType = "portfolio-filter-drawer-content";
  let displayWarning = false;
  let button = "portfolio-filter-drawer-button";
  let buttonApply = "portfolio-filter-button-apply";

  // Check if any filters are enabled
  Object.keys(filters).forEach((key) => {
    if (!isEmpty(filters[key])) {
      displayWarning = true;
    }
  });

  if (showFilters) {
    button = "portfolio-filter-drawer-button-clicked";
  }

  if (displayWarning && !showFilters) {
    button = "portfolio-filter-drawer-button-active";
  }

  if (displayWarning) {
    buttonApply = "portfolio-filter-button-apply-active";
  }

  return (
    <div className="portfolio-filter-container">
      <button
        className={button}
        onClick={() => {
          setShowFilters(!showFilters);
        }}
      >
        {translate("Project Filters")}
      </button>
      {showFilters && (
        <div className={contentType}>
          <div>
            <PortfolioSelectSingle
              placeholder={"Display"}
              value={pageSize}
              onChange={setPageSize}
              options={numberPerPage}
              icon={<i className="fas fa-grip-horizontal"></i>}
            />

            <div className="portfolio-input-container">
              <PortfolioInputSingle
                placeholder={"Project Title"}
                type={"title"}
                filters={filters}
                setFilters={setFilters}
                icon={<i className="fas fa-pen-fancy"></i>}
              />
              <PortfolioInputSingle
                placeholder={"Client Company"}
                type={"clientCompany"}
                filters={filters}
                setFilters={setFilters}
                icon={<i className="far fa-building"></i>}
              />
              <PortfolioSelectSingle
                placeholder={"Sector"}
                type={"sector"}
                filters={filters}
                setFilters={setFilters}
                childSelection={"subsector"}
                options={sectorList()}
                icon={<i className="fas fa-th-large" />}
              />
              <PortfolioSelectSingleFilter
                placeholder={"Subsector"}
                type={"subsector"}
                filters={filters}
                setFilters={setFilters}
                options={subsectorList()}
                filter={filters["sector"]}
                icon={<i className="fas fa-th" />}
              />
              <PortfolioSelectSingle
                placeholder={"Project Type"}
                type={"projectType"}
                filters={filters}
                setFilters={setFilters}
                options={typeList()}
                icon={<i className="fas fa-shapes"></i>}
              />
              <PortfolioSelectSingle
                placeholder={"Project Quality"}
                type={"projectQuality"}
                filters={filters}
                setFilters={setFilters}
                options={qualityList()}
                icon={<i className="fas fa-gem"></i>}
              />

              <PortfolioSelectSingle
                placeholder={"Procurement Method"}
                type={"procurementMethod"}
                filters={filters}
                setFilters={setFilters}
                options={procurementList()}
                icon={<i className="fas fa-project-diagram"></i>}
              />
              <PortfolioInputSingle
                placeholder={"Region / Country / City"}
                type={"location"}
                filters={filters}
                setFilters={setFilters}
                icon={<i className="fas fa-globe-asia"></i>}
              />
              <PortfolioInputSingle
                placeholder={"Tags"}
                type={"searchTags"}
                filters={filters}
                setFilters={setFilters}
                icon={<i className="fas fa-solid fa-tag"></i>}
              />
              <div className="portfolio-filter-input-container">
                <div></div>
                <div className="portfolio-filter-input-col">
                  <button
                    className={buttonApply}
                    onClick={() => {
                      setPortfolioFilters(filters);
                    }}
                  >
                    Apply Filter
                  </button>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const numberPerPage = [
  { label: "4 / Page", value: 4 },
  { label: "8 / Page", value: 8 },
  { label: "12 / Page", value: 12 },
  { label: "16 / Page", value: 16 },
  { label: "20 / Page", value: 20 },
  { label: "24 / Page", value: 24 },
];
