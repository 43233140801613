import React, { useState, useEffect } from "react";
import CashflowGraphMonthly from "./CashflowGraphMonthly";
import CashflowGraphCumulative from "./CashflowGraphCumulative";
import Accordion from "../accordion/Accordion";
import CashflowTableMonthly from "./CashflowTableMonthly";
import CashflowTableCumulative from "./CashflowTableCumulative";
import PropTypes from "prop-types";

import CashflowControlPanel from "./CashflowControlPanel";
import { connect } from "react-redux";

import { combineCashflows, setCashflowTotals } from "./Cashflow.functions";

import { translate } from "../../utils/translation";
import { GENERATED_CASHFLOW, TEMPLATE_CASHFLOW } from "../../actions/types";

import "./Cashflow.css";
import isEmpty from "../../validation/is-empty";

PostContractCashflow.propTypes = {
  // Props
  project: PropTypes.object,
  postContract: PropTypes.object,
  totalCost: PropTypes.object,

  // Cashflow Data
  cashflow_standard_deviation: PropTypes.string,
  cashflow_start_month: PropTypes.string,
  cashflow_start_year: PropTypes.string,
  cashflow_program: PropTypes.string,
  user: PropTypes.object,
  cashflow_basis: PropTypes.string,

  // Template
  cashflowDescription: PropTypes.string,

  cashflowActualsMonthly: PropTypes.string,
  cashflowActualsCumulative: PropTypes.string,
  cashflowForecastMonthly: PropTypes.string,
  cashflowForecastCumulative: PropTypes.string,
  cashflowContractorMonthly: PropTypes.string,
  cashflowContractorCumulative: PropTypes.string,

  // Generated

  cashflowGeneratedDescription: PropTypes.string,

  cashflowGeneratedForecastMonthly: PropTypes.string,
  cashflowGeneratedForecastCumulative: PropTypes.string,
  cashflowGeneratedForecastTotal: PropTypes.string,

  cashflowGeneratedActualsMonthly: PropTypes.string,
  cashflowGeneratedActualsCumulative: PropTypes.string,
  cashflowGeneratedContractorMonthly: PropTypes.string,
  cashflowGeneratedContractorCumulative: PropTypes.string,

  cashflowForecastTotal: PropTypes.string,
  cashflowContractorTotal: PropTypes.string,
  cashflowActualsTotal: PropTypes.string,

  cashflowGeneratedContractorTotal: PropTypes.string,
  cashflowGeneratedActualsTotal: PropTypes.string,
};

function PostContractCashflow(props) {
  // Props
  const { project } = props;
  const { postContract } = props;
  const { totalCost } = props;

  // Cashflow Data
  const { cashflow_standard_deviation } = props;
  const { cashflow_start_month } = props;
  const { cashflow_start_year } = props;
  const { cashflow_program } = props;
  const { user } = props.auth;
  const { cashflow_basis } = props;

  // Template
  const { cashflowDescription } = props;

  const { cashflowActualsMonthly } = props;
  const { cashflowActualsCumulative } = props;
  const { cashflowForecastMonthly } = props;
  const { cashflowForecastCumulative } = props;
  const { cashflowContractorMonthly } = props;
  const { cashflowContractorCumulative } = props;

  // Generated

  const { cashflowGeneratedDescription } = props;

  const { cashflowGeneratedForecastMonthly } = props;
  const { cashflowGeneratedForecastCumulative } = props;
  const { cashflowGeneratedForecastTotal } = props;

  const { cashflowGeneratedActualsMonthly } = props;
  const { cashflowGeneratedActualsCumulative } = props;
  const { cashflowGeneratedContractorMonthly } = props;
  const { cashflowGeneratedContractorCumulative } = props;

  const { cashflowForecastTotal } = props;
  const { cashflowContractorTotal } = props;
  const { cashflowActualsTotal } = props;

  const { cashflowGeneratedContractorTotal } = props;
  const { cashflowGeneratedActualsTotal } = props;

  // State
  const [selectedMonth, setSelectedMonth] = useState(cashflow_start_month);
  const [selectedYear, setSelectedYear] = useState(cashflow_start_year);
  const [cashflowBasis, setCashflowBasis] = useState(cashflow_basis);

  useEffect(() => {
    if (!isEmpty(cashflow_basis)) {
      setCashflowBasis(cashflow_basis);
    } else {
      setCashflowBasis(GENERATED_CASHFLOW);
    }
  }, [cashflow_basis]);

  // Standard Deviation Range
  const [standardDeviation, setStandardDeviation] = useState(
    cashflow_standard_deviation || 2
  );
  const [standardDeviationMin, setStandardDeviationMin] = useState(0.1);
  const [standardDeviationMax, setStandardDeviationMax] = useState(20);
  const [program, setProgram] = useState(cashflow_program);

  const cashflow =
    cashflowBasis === TEMPLATE_CASHFLOW
      ? combineCashflows(
          cashflowDescription,
          cashflowForecastMonthly,
          cashflowForecastCumulative,
          cashflowActualsMonthly,
          cashflowActualsCumulative,
          cashflowContractorMonthly,
          cashflowContractorCumulative
        )
      : combineCashflows(
          cashflowGeneratedDescription,
          cashflowGeneratedForecastMonthly,
          cashflowGeneratedForecastCumulative,
          cashflowGeneratedActualsMonthly,
          cashflowGeneratedActualsCumulative,
          cashflowGeneratedContractorMonthly,
          cashflowGeneratedContractorCumulative
        );

  cashflowBasis === TEMPLATE_CASHFLOW
    ? setCashflowTotals(
        cashflow,
        cashflowForecastTotal,
        cashflowContractorTotal,
        cashflowActualsTotal
      )
    : setCashflowTotals(
        cashflow,
        cashflowGeneratedForecastTotal,
        cashflowGeneratedContractorTotal,
        cashflowGeneratedActualsTotal
      );

  return (
    <div className="post-contract-cashflow-content">
      <div className="post-contract-cashflow-row">
        <CashflowControlPanel
          postContract={postContract}
          user={user}
          standardDeviation={standardDeviation}
          setStandardDeviation={setStandardDeviation}
          standardDeviationMin={standardDeviationMin}
          setStandardDeviationMin={setStandardDeviationMin}
          standardDeviationMax={standardDeviationMax}
          setStandardDeviationMax={setStandardDeviationMax}
          program={program}
          setProgram={setProgram}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          project={project}
          totalCost={totalCost}
          cashflowBasis={cashflowBasis}
          setCashflowBasis={setCashflowBasis}
        />
      </div>
      <Accordion
        label={translate("Cumulative Cashflow")}
        isOpen={true}
        total={
          cashflowBasis === TEMPLATE_CASHFLOW
            ? cashflowForecastTotal
            : cashflowGeneratedForecastTotal
        }
        accordionState={{ state: true, set: () => null }}
      >
        <div className="post-contract-cashflow-row">
          <CashflowTableCumulative cashflow={cashflow} />
          <CashflowGraphCumulative cashflow={cashflow} />
        </div>
      </Accordion>
      <Accordion
        label={translate("Monthly Cashflow")}
        isOpen={true}
        total={
          cashflowBasis === TEMPLATE_CASHFLOW
            ? cashflowForecastTotal
            : cashflowGeneratedForecastTotal
        }
        accordionState={{ state: true, set: () => null }}
      >
        <div className="post-contract-cashflow-row">
          <CashflowTableMonthly cashflow={cashflow} />
          <CashflowGraphMonthly cashflow={cashflow} />
        </div>
      </Accordion>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(PostContractCashflow);
