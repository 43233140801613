import React from "react";
import { translate } from "../../utils/translation";

import "./EstimatesTPILinkedInfoModal.css";

export default function EstimatesTPILinkedInfoModal(props) {
  const { setModal } = props;
  const { isLinkedTPI } = props;

  const hideModal = () => {
    setModal(false);
  };

  return (
    <div className="estimates-TPI-linked-info-modal">
      <div>
        {isLinkedTPI ? (
          <>
            <p>
              You have synchronized {translate("Tender Price Inflation")} turned
              on.
            </p>
            <p>To turn this feature off, click the icon in the Total row.</p>
          </>
        ) : (
          <>
            <p>
              You have synchronized {translate("Tender Price Inflation")} turned
              off.
            </p>
            <p>To turn this feature on, click the icon in the Total row.</p>
          </>
        )}
      </div>
      <div>
        <button
          className="estimates-TPI-linked-info-modal-button"
          onClick={hideModal}
        >
          OK
        </button>
      </div>
    </div>
  );
}
