import { authHeader } from "../../_helpers/auth-header";
import isEmpty from "../../validation/is-empty";

export async function deleteCommentary(data, URLs) {
  // COST PLAN API
  const url = URLs.endpoint().commentary_delete;
  const payload = {
    commentary_id: data.cID,
    type: data.cType,
    projectID: data.projectID,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Delete commentary Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    console.log(erMessage);
  }
}

// Move comment
export async function onMoveComment(data, URLs) {
  // COST PLAN API
  if (isEmpty(URLs)) {
    return null;
  }
  const url = URLs.endpoint().commentary_edit;

  const costidfield =
    data.Module === "PostContract" ? "post_contract_id" : "cost_plan_id";

  const field = JSON.stringify({
    id: data.c.id,
    [costidfield]: data.CostId,

    ...(data.Module !== "PostContract" && { stage: data.c.stage }),
    ...(data.Module !== "PostContract" && { revision: data.c.revision }),
    title: data.c.title,
    text: data.c.text,
    part: data.part,
    project_id: data.projectID,
    type: data.c.type,
    rows: data.c.rows,
  });

  const config = {
    method: "POST",
    body: field,
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let erMessage = "Commentary edit Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}
