import React, { useState } from "react";
import Modal from "../modal/Modal";
import "./Feedback.css";
import { translate } from "../../utils/translation";
import { authHeader } from "../../_helpers/auth-header";
import { feedbackAPIs } from "../../api/api-endpoints/FormDataEndpoints";

export default function Feedback(props) {
  const { user } = props;
  const [modal, setModal] = useState(false);

  return (
    <div className="navigation-link">
      <button
        className="feedback-open"
        onClick={() => {
          setModal(true);
        }}
      >
        <div className="feedback-icon">
          <i className="fas fa-comment-alt" />
        </div>
        {translate("Feedback")}
      </button>
      <Modal
        // Modal Props
        title={"Leave Feedback"}
        Component={FeedbackModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        user={user}
      />
    </div>
  );
}

export function FeedbackModal(props) {
  const { user } = props;
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [enable, setEnable] = useState(true);

  const [error, setError] = useState({});

  return (
    <div className="feedback">
      Your feedback is essential for the continual improvement of Cost Clarity.
      Please select your feedback type, and then leave a comment below!
      <select
        className="feedback-select"
        value={feedbackType}
        onChange={(e) => {
          setFeedbackType(e.target.value);
        }}
      >
        {selectOptions(selectList())}
      </select>
      <TextCounter text={feedbackText} />
      <textarea
        value={feedbackText}
        className="feedback-textarea"
        onChange={(e) => setFeedbackText(e.target.value)}
      />
      <div className="general-row-container">
        <Notify error={error} />
      </div>
      <div className="general-button-container">
        {enable && (
          <button
            className="general-upload-button"
            onClick={() => {
              submitFeedback(
                feedbackText,
                feedbackType,
                user,
                setError,
                setEnable
              );
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export function TextCounter(props) {
  const { text } = props;

  let feedbackType = "feedback-counter-success";
  if (text.length > 1000) {
    feedbackType = "feedback-counter-error";
  }

  return (
    <div className="feedback-text-counter-container">
      <div className={feedbackType}>
        <b>{text.length}</b>
      </div>
      <div>
        <b>/ 1000 </b>
      </div>
    </div>
  );
}

function Notify(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function selectOptions(array) {
  return array.map(function (item, i) {
    return (
      <option key={i} value={item.value}>
        {item.value}
      </option>
    );
  });
}

function selectList() {
  return [
    { value: "Select Type (Required)" },
    { value: "Bug Report" },
    { value: "Feature Suggestion" },
    { value: "General Comment" },
    //{ value: "New Feature Request(opens in new tab)" },
  ];
}

export async function submitFeedback(
  feedbackText,
  feedbackType,
  user,
  setError,
  setEnable
) {
  if (feedbackType === "Select Type (Required)" || feedbackType === "") {
    return setError({
      text: "Please select a feedback type",
      type: "feedback-error",
    });
  }

  if (feedbackText === "") {
    return setError({
      text: "Please enter some feedback",
      type: "feedback-error",
    });
  }

  if (feedbackText.length > 1000) {
    return setError({
      text: "Feedback exceeds text limit",
      type: "feedback-error",
    });
  }

  const url = feedbackAPIs().create_feedback;
  const payload = {
    date: Date.now(),
    text: feedbackText,
    type: feedbackType,
    user_id: user.id,
    authorEmail: user.email,
    authorName: user.name,
    authorSurname: user.surname,
    authorTitle: user.jobTitle,
  };
  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Create feedback Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    return console.log(erMessage);
  }

  setEnable(false);
  return setError({
    text: "Submitted Successfully. Thank you!",
    type: "feedback-success",
  });
}
