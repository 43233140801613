import React from "react";
import PropTypes from "prop-types";

import Cashflow from "./Cashflow";
import isEmpty from "../../validation/is-empty";

import "./CashflowContainer.css";

CashflowContainer.propTypes = {
  project: PropTypes.object,
  postContract: PropTypes.object,
  selectedReport: PropTypes.object,
};

export default function CashflowContainer(props) {
  const { project } = props;
  const { postContract } = props;
  const { selectedReport } = props;

  const report = postContract?.data?.find(
    (p) => p.PostContractId === selectedReport.PostContractId
  );

  if (isEmpty(report)) {
    return <div>{"No Report Found"}</div>;
  }

  const component = report?.cashflow;

  if (isEmpty(component)) {
    return <div>{"No Cashflow Found"}</div>;
  }

  //Control Base: Template

  const cashflowDescription = component?.templateCashflow.map(
    (cf) => cf.description
  );

  const cashflowForecastMonthly = component?.templateCashflow.map(
    (cf) => cf.forecastMonthly
  );
  const cashflowForecastCumulative = component?.templateCashflow.map(
    (cf) => cf.forecastCumulative
  );
  const cashflowForecastTotal = total(
    component?.templateCashflow,
    "forecastMonthly"
  );

  const cashflowActualsMonthly = component?.templateCashflow.map(
    (cf) => cf.actualCostMonthly
  );
  const cashflowActualsCumulative = component?.templateCashflow.map(
    (cf) => cf.actualCostCumulative
  );
  const cashflowActualsTotal = total(
    component?.templateCashflow,
    "actualCostMonthly"
  );

  const cashflowContractorMonthly = component?.templateCashflow.map(
    (cf) => cf.contractorMonthly
  );
  const cashflowContractorCumulative = component?.templateCashflow.map(
    (cf) => cf.contractorCumulative
  );
  const cashflowContractorTotal = total(
    component?.templateCashflow,
    "contractorMonthly"
  );

  //Control Base: Generated
  const cashflowGeneratedDescription = component?.generatedCashflow?.map(
    (cf) => cf.description
  );

  const cashflowGeneratedForecastMonthly = component?.generatedCashflow?.map(
    (cf) => cf.forecastMonthly
  );
  const cashflowGeneratedForecastCumulative = component?.generatedCashflow?.map(
    (cf) => cf.forecastCumulative
  );
  const cashflowGeneratedForecastTotal = total(
    component?.generatedCashflow,
    "forecastMonthly"
  );

  const cashflowGeneratedActualsMonthly = component?.generatedCashflow?.map(
    (cf) => cf.actualCostMonthly
  );
  const cashflowGeneratedActualsCumulative = component?.generatedCashflow?.map(
    (cf) => cf.actualCostCumulative
  );
  const cashflowGeneratedActualsTotal = total(
    component?.generatedCashflow,
    "actualCostMonthly"
  );

  const cashflowGeneratedContractorMonthly = component?.generatedCashflow?.map(
    (cf) => cf.contractorMonthly
  );
  const cashflowGeneratedContractorCumulative =
    component?.generatedCashflow?.map((cf) => cf.contractorCumulative);

  const cashflowGeneratedContractorTotal = total(
    component?.generatedCashflow,
    "contractorMonthly"
  );

  return (
    <div className="post-contract-cashflow-container">
      <Cashflow
        project={project}
        postContract={postContract}
        totalCost={component.forecastAdjustedContractSum}
        // Cashflow Data
        cashflow_start_month={component.startMonth}
        cashflow_start_year={component.startYear}
        cashflow_standard_deviation={component.standardDeviation}
        cashflow_program={component.program}
        cashflow_basis={component.cashflowBasis}
        // Template
        cashflowDescription={cashflowDescription}
        cashflowForecastMonthly={cashflowForecastMonthly}
        cashflowForecastCumulative={cashflowForecastCumulative}
        cashflowActualsMonthly={cashflowActualsMonthly}
        cashflowActualsCumulative={cashflowActualsCumulative}
        cashflowContractorMonthly={cashflowContractorMonthly}
        cashflowContractorCumulative={cashflowContractorCumulative}
        cashflowContractorTotal={cashflowContractorTotal}
        cashflowActualsTotal={cashflowActualsTotal}
        cashflowForecastTotal={cashflowForecastTotal}
        // Generated
        cashflowGeneratedDescription={cashflowGeneratedDescription}
        cashflowGeneratedForecastMonthly={cashflowGeneratedForecastMonthly}
        cashflowGeneratedForecastCumulative={
          cashflowGeneratedForecastCumulative
        }
        cashflowGeneratedForecastTotal={cashflowGeneratedForecastTotal}
        cashflowGeneratedActualsMonthly={cashflowGeneratedActualsMonthly}
        cashflowGeneratedActualsCumulative={cashflowGeneratedActualsCumulative}
        cashflowGeneratedContractorMonthly={cashflowGeneratedContractorMonthly}
        cashflowGeneratedContractorCumulative={
          cashflowGeneratedContractorCumulative
        }
        cashflowGeneratedContractorTotal={cashflowGeneratedContractorTotal}
        cashflowGeneratedActualsTotal={cashflowGeneratedActualsTotal}
      />
    </div>
  );
}

function total(array, key) {
  let ttl = 0;

  array?.forEach((element) => {
    ttl += element[key];
  });

  return ttl;
}
