import { authHeader } from "../../_helpers/auth-header";

export async function onSetCriticalVariations(data) {
  const url = data.url;

  const { postContract, sheetName } = data;
  const { postContractId } = data;
  const { projectId } = data;

  const field = JSON.stringify({
    isCriticalIDs: data.isCriticalIDs,
    notCriticalIDs: data.notCriticalIDs,
    projectID: projectId,
    postContractId: postContractId,
    sheetName: sheetName,
  });

  const config = {
    method: "PUT",
    body: field,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  data.setError({
    text: "Saving...",
    type: "feedback-success",
  });
  let response = await fetch(url, config);
  if (response.ok) {
    data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });

    const URL = window.location.href;
    const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
    postContract.set(
      await postContract.get(projectId, postContractId, sheetName)
    );
    data.setShow(false);
  } else {
    console.log("Critical records save Error");
  }
}
