import PropTypes from "prop-types";

ExpandableRow.propTypes = {
  headings: PropTypes.array,
  values: PropTypes.array,
};

export function ExpandableRow(props) {
  const { headings } = props;
  const { values } = props;

  const displayHeadings = [];
  const displayValues = [];

  headings.forEach((h) => {
    displayHeadings.push(
      <div className="pctm-value-cell-head">
        <b>{h}</b>
      </div>
    );
  });

  values.forEach((v) => {
    displayValues.push(
      <div className="pctm-value-cell-head">
        <b>{v}</b>
      </div>
    );
  });

  return (
    <div className="pctm-container">
      <div className="pctm-row-head">{displayHeadings}</div>

      <div className="pctm-row">{displayValues}</div>
    </div>
  );
}

OneValueRow.propTypes = {
  heading1: PropTypes.object,
  value1: PropTypes.object,
};

export function OneValueRow(props) {
  const { heading1 } = props;
  const { value1 } = props;

  return (
    <div className="pctm-container-horizontal">
      <div className="pctm-row-head-text">
        <div className="pctm-value-head-text">
          <b>{heading1}</b>
        </div>
      </div>
      <div className="pctm-row">
        <div className="pctm-value-cell-text">{value1}</div>
      </div>
    </div>
  );
}

DocumentLink.propTypes = {
  document_link: PropTypes.object,
};

export function DocumentLink(props) {
  const { document_link } = props;

  // Check if a URL has https://
  const regexp = /^(?:[a-z]+:)?\/\//i;

  if (document_link.length === 0) {
    return null;
  }

  let URL = "";
  if (regexp.test(document_link)) {
    URL = document_link;
  } else {
    URL = "//" + document_link;
  }

  return (
    <div className="pctm-container-horizontal">
      <div className="pctm-row-head-text">
        <div className="pctm-value-head-text">
          <b>Document Link</b>
        </div>
      </div>
      <div className="pctm-row">
        <a
          className="pctm-value-cell-text"
          href={URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Link to External Site
        </a>
      </div>
    </div>
  );
}
