// React
import React, { useState } from "react";
import Modal from "../modal/Modal";
import isEmpty from "../../validation/is-empty";

import "./PortfolioEdit.css";

export function ModalMultiple(props) {
  const { Component, label } = props;
  const { selectedData } = props;
  const [modal, setModal] = useState(false);

  return (
    <div className="portfolio-edit-col">
      <div className="portfolio-edit-row">
        <Modal
          // Modal Props
          title={label}
          Component={Component}
          modal={modal}
          setModal={setModal}
          // Component Props
          show={modal}
          setShow={setModal}
          {...props}
        />
        <button
          className="portfolio-edit-modal-button"
          onClick={() => {
            setModal(true);
          }}
        >
          {label}
        </button>
        <DisplayName />
      </div>
    </div>
  );

  function DisplayName() {
    if (isEmpty(selectedData)) {
      return (
        <div className="portfolio-edit-selection-number">
          <b>0</b>
        </div>
      );
    }

    return (
      <div className="portfolio-edit-selection-number">
        <b>{selectedData.length}</b>
      </div>
    );
  }
}
