import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../utils/translation";

export default function ProjectNavigationSustainability(props) {
  const { projectID, show, setShow, setPCShow, setCPShow, project } = props;
  let section = window.location.href.includes("sustainability");

  let name = "project-navigation-section";
  if (section) {
    name = "project-navigation-section-selected";
  }

  const tabs = [
    {
      name: "Carbon Cost",
      url: `/project/${projectID}/sustainability/carbon-cost`,
    },
    {
      name: "Whole Life Cost",
      url: `/project/${projectID}/sustainability/whole-life-cost`,
    },
    {
      name: "Environmental Cost",
      url: `/project/${projectID}/sustainability/environmental-cost`,
    },
  ];

  return (
    <div className="project-navigation-tab-item-group">
      <button
        className="project-navigation-tab-link"
        onClick={() => {
          setShow((state) => !state);
          setCPShow(() => false);
          setPCShow(() => false);
        }}
      >
        <div className={name}>
          <div className="project-navigation-label">
            <div className="front-icon">
              <div className="icon-container">
                <i className="fas fa-leaf" style={{ marginRight: "10px" }}></i>
              </div>
              {translate("Sustainability")}
            </div>
            {show && (
              <div className="icon">
                <i className="far fa-minus-square" />
              </div>
            )}
            {!show && (
              <div className="icon">
                <i className="far fa-plus-square" />
              </div>
            )}
          </div>
        </div>
      </button>

      {tabs.map((t) => {
        if (show) {
          return (
            <Tab
              key={t.name}
              project={project}
              t={t.name}
              url={t.url}
              section={section}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

function Tab(props) {
  const { t, url } = props;
  const { section } = props;

  let type = "cost-planning-tab-item";
  if (
    (section &&
      window.location.href.includes(t.toLowerCase().replace(/\s+/g, "-"))) ||
    (section &&
      t.toLowerCase().includes("delays") &&
      window.location.href.includes("delays"))
  ) {
    type = "cost-planning-tab-item-selected";
  }

  return (
    <Link key={t} to={url} className="cost-planning-tab-link">
      <div className={type}>
        <div className="cost-planning-tab-link-text">{translate(t)}</div>
      </div>
    </Link>
  );
}
