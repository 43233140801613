import React, { useState } from "react";
import DesignContingencyExpenditureChange from "../charts/DesignContingencyExpenditureChange";

import EstimateChangesTable from "./EstimateChangesTable";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import "./ReconciliationScopeDesignChanges.css";
import "./ChangesCommentary.css";

export default function ReconciliationScopeDesignChanges(props) {
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;
  const { e } = props;

  const { reconciliationData } = props;

  const [show, setShow] = useState(e.stage_code);

  if (reconciliationData.reconciliationType === "RELEASE") {
    return null;
  }

  if (isEmpty(e.designContingency) || isEmpty(e.changes)) {
    return null;
  }

  // If only showing this stage then set the estimates to this estimate only
  let estimates = [];
  if (show === e.stage_code) {
    estimates = [e];
  }

  if (show === "All") {
    // Filter to this or earlier Cost Plans
    const stage = CP.stage;
    const part = e.part;
    CPs.data.forEach((CPStage) => {
      if (!isEmpty(CPStage.versions)) {
        selectComparisonEstimate(estimates, CP, CPStage, stage, part);
      }
    });
  }

  return (
    <div className="recon-scope-design-changes">
      <div className="recon-section-title">
        <h1 className="display-4">{translate("Scope Design Changes")}</h1>
        <ComparisonToggle e={e} show={show} setShow={setShow} />
      </div>
      <DesignContingencyExpenditureChange
        project={project}
        estimate={e}
        estimates={estimates}
      />
      <div className="changes-table-row">
        <EstimateChangesTable
          project={project}
          estimate={e}
          estimates={estimates}
        />
      </div>
    </div>
  );
}

function selectComparisonEstimate(estimates, CP, CPStage, stage, part) {
  // If its the currently viewed stage, get the selected revision
  if (CPStage.stage === CP.stage) {
    CP.estimates.forEach((estimate) => {
      if (estimate.part === part) {
        estimates.push(estimate);
      }
    });
  }

  // If it's a previous stage, only get the latest revision
  if (CPStage.stage < CP.stage) {
    CPStage.versions[0].estimates.forEach((estimate) => {
      if (estimate.stage <= stage && estimate.part === part) {
        estimates.push(estimate);
      }
    });
  }
}

function ComparisonToggle(props) {
  const { e } = props;
  const { show, setShow } = props;

  let graphToggle;
  let tableToggle;
  if (show === e.stage_code) {
    graphToggle = "recon-comarison-toggle-button-in-left";
    tableToggle = "recon-comarison-toggle-button-out-right";
  } else {
    graphToggle = "recon-comarison-toggle-button-out-left";
    tableToggle = "recon-comarison-toggle-button-in-right";
  }

  return (
    <div className="recon-comparison-toggle">
      <button
        className={graphToggle}
        onClick={() => {
          setShow(e.stage_code);
        }}
      >
        <h1 className="display-4">{e.stage_code}</h1>
      </button>
      <button
        className={tableToggle}
        onClick={() => {
          setShow("All");
        }}
      >
        <h1 className="display-4">{translate("All")}</h1>
      </button>
    </div>
  );
}
