// Import React
import React, { useState } from "react";
import { hasRoles } from "../../utils/roles";

import "./CategoryDelete.css";
import { deleteArticle } from "./MarketInsights.functions";
import Spinner from "../common/Spinner";

export default function ArticleDelete(props) {
  const { selectedArticle } = props;
  const { setInsights } = props;
  const { user } = props;
  const { isVisible } = props;

  const URL = window.location.href;

  const [loading, setLoading] = useState(false);

  if (!URL.includes("article/") && !isVisible) {
    return null;
  }

  if (!hasRoles(user.roles, ["Admin"])) {
    return null;
  }

  if (loading) {
    return <Spinner width={"20px"} height={"20px"} />;
  }

  return (
    <div className="articles-card-button-container">
      <button
        id="article-delete-button"
        className="article-delete-button"
        onClick={async () => {
          setLoading(true);
          await deleteArticle(
            selectedArticle.id,
            selectedArticle.market_insight_id,
            setInsights
          );
        }}
      >
        <i className="fas fa-times" />
      </button>
      <span className="tooltiptext">Delete Article</span>
    </div>
  );
}
