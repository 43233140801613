import React, { useState } from "react";

import EstimateBreakdownComment from "./EstimateBreakdownComment";
import Modal from "../modal/Modal";

import "./EstimateBreakdownCommentModal.css";
import { translate } from "../../utils/translation";
import EstimateBreakdownTable from "../estimate-breakdown-table/EstimateBreakdownTable";

export default function EstimateBreakdownCommentModal(props) {
  const { rowType } = props;
  const { breakdown } = props;
  const { projectID } = props;
  const { CPs } = props;
  const { project } = props;
  const { rowID } = props;
  const { filtered } = props;

  const [modal, setModal] = useState(false);

  if (rowType !== "ebt-row" && rowType !== "ebt-row-filtered") {
    return null;
  }

  return (
    <div className="estimate-breakdown-comment-open">
      <button
        className="estimate-breakdown-comment-modal-button"
        onClick={() => {
          if (filtered === undefined) {
            setModal(true);
          }
        }}
      >
        <CommentIcon
          has_comments={breakdown.has_comments}
          notify_estimate_comment={breakdown.notify_estimate_comment}
          filtered={filtered}
        />
      </button>
      <Modal
        Component={EstimateBreakdownComment}
        title={translate("Breakdown Comments " + breakdown.id)}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        CPs={CPs}
        breakdown={breakdown}
        projectID={projectID}
        project={project}
        rowID={rowID}
      />
    </div>
  );
}

export function FilteredEstimatedBreakDownModal(props) {
  const { estimateID } = props;
  const { data } = props;
  const { CPs } = props;
  const { project } = props;
  const { selectedRow } = props;

  const [modal, setModal] = useState(false);
  const [hasComments, setHasComments] = useState(false);

  data?.forEach((element) => {
    if (element.breakdown !== undefined) {
      element.breakdown.forEach((bd) => {
        if (bd.has_comments && !hasComments) {
          setHasComments(bd.has_comments);
        }
      });
    }
  });

  return (
    <div className="estimate-edit-wrapper">
      <button
        className="estimate-edit-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <div>
          <i className="fas fa-solid fa-comments"></i>
        </div>
      </button>
      <FilteredEstimateBreakdownCommentsModal
        modal={modal}
        setModal={setModal}
        // Component Props
        data={data}
        CPs={CPs}
        project={project}
        estimateID={estimateID}
        selectedRow={selectedRow}
      />
    </div>
  );
}

export function FilteredEstimateBreakdownCommentsModal(props) {
  const { modal, setModal } = props;
  const { estimateID } = props;
  const { data } = props;
  const { CPs } = props;
  const { project } = props;
  const { selectedRow } = props;

  return (
    <Modal
      Component={EstimateBreakdownTable}
      title={translate("Breakdown Comments")}
      modal={modal}
      setModal={setModal}
      // Component Props
      show={modal}
      data={data}
      CPs={CPs}
      project={project}
      estimateID={estimateID}
      selectedRow={selectedRow}
      filtered={true}
    />
  );
}

export function FilteredEstimateBreakdownComment(props) {
  const { CPs } = props;
  const { project } = props;
  let { selectedRow } = props;
  const { projectID } = props;

  return (
    <div>
      <EstimateBreakdownTable
        estimateID={CPs.estimateID}
        //  data={e.rows}
        projectID={projectID}
        CPs={CPs}
        project={project}
        selectedRow={selectedRow}
        filtered={true}
      />
    </div>
  );
}

function CommentIcon(props) {
  const { has_comments } = props;
  const { notify_estimate_comment } = props;
  const { filtered } = props;

  let iconType = "far fa-comment";
  let iconColour = "estimate-breakdown-comment-icon-off";

  if (has_comments && notify_estimate_comment) {
    iconColour = "estimate-breakdown-comment-icon-on";
    if (filtered !== undefined)
      iconColour = "estimate-breakdown-comment-icon-on-filtered";
    iconType = "fas fa-comment";
  }

  if (has_comments && !notify_estimate_comment) {
    iconColour = "estimate-breakdown-comment-icon-off";
    if (filtered !== undefined)
      iconColour = "estimate-breakdown-comment-icon-off-filtered";
    iconType = "fas fa-comment";
  }

  return (
    <div className={iconColour}>
      <i className={iconType} />
    </div>
  );
}
