import React, { useState } from "react";

import "./Correspondence.css";

import { CORRESPONDENCE } from "./Correspondence.strings";
import Modal from "../modal/Modal";

import {
  useDefaultPortfolio,
  usePortfolioProjects,
} from "../../api/portfolio/PortfolioAPI";

import CorrespondenceNavIcon from "./CorrespondenceNavIcon";
import CorrespondenceModal from "./CorrespondenceModal";

export default function Correspondence(props) {
  const { user } = props;
  const [modal, setModal] = useState(false);

  // Portfolio State
  const { selectedPortfolio } = useDefaultPortfolio();
  const {
    projectsLoading: isProjectsLoading = true,
    projects: projectsWithCostPlan,
  } = usePortfolioProjects(selectedPortfolio.id, { includeCostPlanData: true });

  return (
    <div className="navigation-link">
      <button
        className={`correspondence-open ${isProjectsLoading ? "loading" : ""}`}
        onClick={() => {
          return !isProjectsLoading && setModal(true);
        }}
      >
        <div className="correspondence-icon">
          <CorrespondenceNavIcon isProjectsLoading={isProjectsLoading} />
        </div>
        {CORRESPONDENCE.TITLE}
      </button>
      <Modal
        Component={CorrespondenceModal}
        title={CORRESPONDENCE.TITLE}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        user={user}
        projects={projectsWithCostPlan}
      />
    </div>
  );
}
