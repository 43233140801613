import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";

import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../modal/Modal";

export const commentary = [
  "Documentation Used to Prepare This Cost Plan",
  "Anticipated Program, Staging and Escalation Forecast",
  "Procurement Strategy and Contract",
  "Contingency Allowances",
  "Assumptions and Clarifications",
  "Custom Title",
];
export const costreportcommentary = ["Custom Title"];

export const documentation = `This Cost Plan has been prepared based upon the [refer to information / drawing pack issued] prepared by [insert Architect / Designer or other relevant party] and received on [insert date of issue].
This includes the following General Arrangement drawings:

[insert drawing references and titles]
`;

export const program =
  "At this stage, and for the purposes of preparing this Cost Plan, Tender Price Escalation has been excluded.  However, it is assumed that the project will be undertaken in a single stage and that no unreasonable requirements or obligations will be placed upon the contractor.  With regards to Contractor’s Preliminaries, we have engaged with local Tier 1 builders who are delivering projects of a similar nature and scale.  The allowances that we have included within this Cost Plan have therefore been benchmarked and market tested appropriately.";

export const procurement =
  "It is assumed that the project will be competitively tendered on a Design and Construct basis and to a minimum of three, suitably experienced contractors. It is assumed that the form of contract will be in accordance with Australian Standards and will incorporate only a minimal number of amendments, which should not be onerous. ";

export const contingency = `<p>Please note the following with regards to the Contingency allowances that have been nominated within this Cost Plan.</p><p><br></p><p class="ql-indent-1">• Design Development Contingency generally allows for future development of the design, clarification of design details, further refinement of the project scope and tender pricing risk. Within this Cost Plan, Design Development Contingency has been calculated based upon 4.5% of the total trade cost and is considered to be at the lower end of the range for the current stage of the design. We would typically expect Design Development Contingency to range between 4.5% and 10%, depending on the nature and complexity of the project. Please also note that the allowance for Design Development Contingency is also anticipated to reduce as the design is progressed and detailed further, and that this will be offset by greater accuracy in our detailed pricing. This is articulated in the diagram above.</p><p class="ql-indent-1"><br></p><p class="ql-indent-1">• Construction Contingency allows for construction and site-based risks such as latent conditions and onsite design and construction coordination. Construction Contingency is currently excluded from the Total Anticipated Construction Cost.</p><p class="ql-indent-1"><br></p><p>Please note that the Design Development and Construction Contingency allowances are not intended to pay for client-instigated scope changes or variations. Should there be a requirement for a separate ‘Client Contingency’ to fund potential client-led changes to either the design or the scope, then further budgetary sums should be allocated to the project at the client’s direction.</p>`;

export const assumptions =
  "We have provided below a summary of the assumptions and clarifications that have been incorporated within this Cost Plan as well as nominating specific exclusions.";

export async function postCommentary(data, URLs) {
  let enteredTitle = data.title;

  if (data.showTitle) {
    enteredTitle = data.customTitle;
  }

  data.setError({
    text: tranStr("Creating..."),
    type: "feedback-success",
  });

  const costidfield =
    data.Module === "PostContract" ? "post_contract_id" : "cost_plan_id";
  const field = JSON.stringify({
    project_id: data.project_id,
    [costidfield]: data.CostId,
    ...(data.Module !== "PostContract" && { stage: data.stage }),
    ...(data.Module !== "PostContract" && { revision: data.revision }),
    title: enteredTitle,
    text: data.text,
    part: data.part,
  });

  // COST PLAN API
  const url = URLs.endpoint().commentary_create;
  const config = {
    method: "POST",
    body: field,
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    await URLs.updateStateFunction();

    data.setError({
      text: tranStr("Created Successfully"),
      type: "feedback-success",
    });

    // close modal after timeout
    AutoCloseModal(data.setShow);
  } else {
    // Response Not OK
    data.setError({
      text: tranStr("Creation Error"),
      type: "feedback-error",
    });
  }
}

export function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}

// UPLOAD FILE

export async function uploadFile(data, URLs) {
  let enteredTitle = data.title;

  if (data.showTitle) {
    enteredTitle = data.customTitle;
  }

  if (isEmpty(enteredTitle)) {
    return data.setError({
      text: tranStr("Please complete Title"),
      type: "feedback-error",
    });
  }

  if (data.file === null) {
    return data.setError({
      text: tranStr("Please upload file"),
      type: "feedback-error",
    });
  }

  data.setError({
    text: tranStr("Creating..."),
    type: "feedback-success",
  });

  const costidfield =
    data.Module === "PostContract" ? "post_contract_id" : "cost_plan_id";
  let field = JSON.stringify({
    project_id: data.project_id,
    [costidfield]: data.CostId,
    ...(data.Module !== "PostContract" && { stage: data.stage }),
    ...(data.Module !== "PostContract" && { revision: data.revision }),
    title: enteredTitle,
    part: data.part,
    text: data.text,
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", field);

  // COST PLAN API
  const url = URLs.endpoint().commentary_upload_table; //DIVAKER
  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    await URLs.updateStateFunction();

    data.setError({
      text: tranStr("Created Successfully"),
      type: "feedback-success",
    });

    // close modal after timeout
    AutoCloseModal(data.setShow);
  } else {
    // Response Not OK
    data.setError({
      text: tranStr("Creation Error"),
      type: "feedback-error",
    });
  }
}
