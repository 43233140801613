// React
import React, { useState } from "react";

// API
import {
  deletePortfolio,
  addPortfolio,
} from "../../api/portfolio/PortfolioAPI";

// Style
import "./PortfolioButtons.css";
import Spinner from "../common/Spinner";

export default function PortfolioButtons(props) {
  const { portfolioId } = props;
  const { portfolios } = props;

  const { isDropEnabled, setIsDropEnabled } = props;
  const { isEditEnabled, setIsEditEnabled } = props;
  const { user } = props;

  return (
    <div className="portfolio-buttons-item-button-row">
      <AddPortfolioButton portfolios={portfolios} user={user} />
      <ProjectButton
        portfolioId={portfolioId}
        isDropEnabled={isDropEnabled}
        setIsDropEnabled={setIsDropEnabled}
        setIsEditEnabled={setIsEditEnabled}
      />
      <EditButton
        portfolioId={portfolioId}
        setIsDropEnabled={setIsDropEnabled}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
      />
      <DeleteButton
        portfolioId={portfolioId}
        portfolios={portfolios}
        setIsDropEnabled={setIsDropEnabled}
        setIsEditEnabled={setIsEditEnabled}
      />
    </div>
  );
}

function AddPortfolioButton(props) {
  const { portfolios } = props;
  const { user } = props;
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <div>
        <Spinner width={"30px"} />
      </div>
    );
  }

  return (
    <button
      className="portfolio-buttons-item-add-button"
      id="button-add-portfolio"
      onClick={async () => {
        setLoading(true);
        await addPortfolio(
          `New Portfolio`,
          user.id // activeDirectoryId
        );
        portfolios.set(await portfolios.get());
        setLoading(false);
      }}
    >
      <i className="fas fa-plus"></i>
    </button>
  );
}

function ProjectButton(props) {
  const { portfolioId } = props;
  const { isDropEnabled, setIsDropEnabled } = props;
  const { setIsEditEnabled } = props;

  if (portfolioId === "all-projects") {
    return null;
  }

  let addButtonClass = "portfolio-buttons-item-add-button";
  if (isDropEnabled) {
    addButtonClass = "portfolio-buttons-item-add-button-clicked";
  }

  return (
    <button
      className={addButtonClass}
      id="add-projects-to-portfolio-button"
      onClick={async () => {
        setIsDropEnabled(!isDropEnabled);
        setIsEditEnabled(false);
      }}
    >
      <i className="fas fa-building"></i>
    </button>
  );
}

function EditButton(props) {
  const { portfolioId } = props;
  const { setIsDropEnabled } = props;
  const { isEditEnabled, setIsEditEnabled } = props;

  if (portfolioId === "all-projects") {
    return null;
  }

  let editButtonClass = "portfolio-buttons-item-add-button";
  if (isEditEnabled) {
    editButtonClass = "portfolio-buttons-item-add-button-clicked";
  }

  return (
    <button
      id="edit-portfolio-button"
      className={editButtonClass}
      onClick={async () => {
        setIsDropEnabled(false);
        setIsEditEnabled(!isEditEnabled);
      }}
    >
      <i className="fas fa-cog"></i>
    </button>
  );
}

function DeleteButton(props) {
  const { portfolioId } = props;
  const { portfolios } = props;
  const { setIsDropEnabled } = props;
  const { setIsEditEnabled } = props;
  const [loading, setLoading] = useState(false);

  if (portfolioId === "all-projects") {
    return null;
  }

  if (loading) {
    return (
      <div>
        <Spinner width={"30px"} />
      </div>
    );
  }

  return (
    <button
      id="delete-portfolio-button"
      className="portfolio-buttons-item-delete-button"
      onClick={async () => {
        const confirm = window.confirm(
          `This will delete the Portfolio for you and all other users, and cannot be undone.`
        );

        if (!confirm) {
          return;
        }
        setLoading(true);
        setIsDropEnabled(false);
        setIsEditEnabled(false);
        await deletePortfolio(portfolioId);
        portfolios.set(await portfolios.get());
        setLoading(false);
      }}
    >
      <i className="fas fa-trash-alt"></i>
    </button>
  );
}
