import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";

import { determineSubLocation } from "./EstimateCommentary.functions";
import EstimateCommentary from "./EstimateCommentary";
import { hasRoles } from "../../utils/roles";

import Modal from "../modal/Modal";

import "./EstimateCommentaryModal.css";
import { translate } from "../../utils/translation";

export default function EstimateCommentaryModal(props) {
  const { user } = props;
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { commentNumber } = props;
  const { URLs } = props;
  const { CostId } = props;
  const { Module } = props;

  const [ectBasis, setBasis] = useState("TEXT");

  const [modal, setModal] = useState(false);

  // No Cost Plans
  if (isEmpty(CP)) {
    return null;
  }

  // Hide if not Commentary
  let subLocation = determineSubLocation();
  if (subLocation !== "commentary" && subLocation !== "Commentary") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        Component={EstimateCommentary}
        title={translate("Commentary")}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        // Component Props
        project={project}
        CP={CP}
        CPs={CPs}
        project_id={project.id}
        commentNumber={commentNumber}
        ectBasis={ectBasis}
        setBasis={setBasis}
        URLs={URLs}
        CostId={CostId}
        Module={Module}
      />
      <button
        className="cost-planning-subtotal-open"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Add Commentary")}
      </button>
    </div>
  );
}
