import React, { useState } from "react";

import ModalEditEstimate from "./EstimatesEdit";
import BudgetAllocation from "../project-budget/ProjectBudgetAllocationModal";
import ExportEstimate from "../export/ExportEstimate";
import DeleteEstimateButton from "./EstimateDeleteButton";
import AreaScheduleAllocationModal from "../cost-planning-area-schedules/AreaScheduleAllocationModal";
import CreateBenchmarkEstimateModal from "../cost-planning-benchmark/CreateBenchmarkEstimateModal";

import "./EstimateControl.css";

export default function EstimateControl(props) {
  const { project } = props;
  const { user } = props;
  const { CP } = props;
  const { CPs } = props;
  const { estimate } = props;

  const [isOpen, setIsOpen] = useState(false);

  let classType = "estimate-edit-button";
  if (isOpen) {
    classType = "estimate-edit-button-clicked";
  }

  return (
    <>
      <button
        id={"estimate-control-open-button"}
        className={classType}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="fas fa-cog"></i>
      </button>
      <Control
        project={project}
        user={user}
        CP={CP}
        CPs={CPs}
        estimate={estimate}
        isOpen={isOpen}
      />
    </>
  );
}

export function Control(props) {
  const [isDeleting, setIsDeleting] = useState(false);

  const { project } = props;
  const { user } = props;
  const { CP } = props;
  const { CPs } = props;
  const { estimate } = props;
  const { isOpen } = props;

  let classType = "estimate-control";
  if (project.project_phase !== "COST_PLANNING") {
    classType = "estimate-control set-margin-left";
  }
  if (!isOpen) {
    classType = "estimate-control-hidden";
  }

  return (
    <div className={classType}>
      <ModalEditEstimate
        user={user}
        CPs={CPs}
        project={project}
        project_id={project.id}
        estimate={estimate}
      />
      <BudgetAllocation
        user={user}
        CP={CP}
        CPs={CPs}
        project={project}
        project_id={project.id}
        estimate={estimate}
      />
      <AreaScheduleAllocationModal
        user={user}
        CP={CP}
        CPs={CPs}
        project={project}
        project_id={project.id}
        estimate={estimate}
      />
      <CreateBenchmarkEstimateModal
        user={user}
        CP={CP}
        CPs={CPs}
        project={project}
        estimate={estimate}
      />
      <ExportEstimate estimate={estimate} />
      <DeleteEstimateButton
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        estimate_id={estimate.id}
        estimateName={estimate.name}
        CPs={CPs}
        project_id={project.id}
        user={user}
        project={project}
      />
    </div>
  );
}
