import React, { useState } from "react";
import { hasRoles } from "../../utils/roles";
import CostPlanningSubTotalDonutModal from "./CostPlanningSubtotalDonutModal";
import Modal from "../modal/Modal";

export default function SubTotalSelection(props) {
  const { projectId, user, estimate, CPs, project } = props;

  const [modal, setModal] = useState(false);
  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }
  return (
    <div className="costplanning-subtotal-button" title="Select sub totals">
      <button
        className="fas fa-pen-nib"
        onClick={() => {
          setModal(true);
        }}
      ></button>
      <Modal
        // Modal Props
        title={"Select Subtotals"}
        Component={CostPlanningSubTotalDonutModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        projectId={projectId}
        estimate={estimate}
        CPs={CPs}
        project={project}
        {...props}
      />
    </div>
  );
}
