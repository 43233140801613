// React
import React, { useState } from "react";
import { translate, tranStr } from "../../utils/translation";
import PropTypes from "prop-types";
import Modal from "../modal/Modal";
import ProjectControlDrawer from "../project-control/ProjectControlDrawer";
import PostContractPanel from "./PostContractPanel";

import Spinner from "../common/Spinner";
import { onDelete } from "../../api/post-contract/PostContractDeleteAPI";

// Functions
import { hasRoles } from "../../utils/roles";

import { PostContractForm } from "./PostContractForm";

import "./PostContractButtons.css";
import isEmpty from "../../validation/is-empty";
import EstimateCommentaryModal from "../estimate-commentary/EstimateCommentaryModal";
import { pc2APIs } from "../../api/api-endpoints/PostContract2.0Endpoints";

// PCRA Components
import PostContractPCRAModalTrigger from "../post-contract-pcra/PostContractPCRAModalTrigger";

PostContractButtons.propTypes = {
  projectID: PropTypes.string,
  project: PropTypes.object,
  postContract: PropTypes.object,
  postContractReports: PropTypes.array,
  postContractForm: PropTypes.object,
  selectedReport: PropTypes.object,
  selectedPackage: PropTypes.object,
  packages: PropTypes.array,
  showCRPanel: PropTypes.bool,
  showPCPanel: PropTypes.bool,
  user: PropTypes.object,
  setShowCRPanel: PropTypes.func,
  setShowPCPanel: PropTypes.func,
};

export default function PostContractButtons(props) {
  const { projectID } = props;
  const { project } = props;
  const { postContract } = props;
  const { postContractReports } = props;
  const { postContractForm } = props;

  const { selectedReport } = props;
  const { selectedPackage } = props;
  const { packages } = props;
  const { showCRPanel, setShowCRPanel } = props;
  const { showPCPanel, setShowPCPanel } = props;
  const { user } = props;

  const [showPostContract, setShowPostContract] = useState(false);

  const isProjectPhasePostContract = project.project_phase === "POST_CONTRACT";
  if (
    !hasRoles(user.roles, ["CostManager"]) ||
    !window.location.href.includes("post-contract")
  ) {
    return null;
  }

  return (
    <div className="post-contract-control-panel">
      <div className="post-contract-control-panel-buttons">
        {isProjectPhasePostContract && (
          <>
            <PostContractPanelWrapper
              user={user}
              selectedPackage={selectedPackage}
              project={project}
              selectedReport={selectedReport}
              postContract={postContract}
              postContractForm={postContractForm}
              setShowCRPanel={setShowCRPanel}
              setShowPCPanel={setShowPCPanel}
              showPostContract={showPostContract}
              setShowPostContract={setShowPostContract}
            />
            <DashboardPanel
              showDashboard={showCRPanel}
              setShowDashboard={setShowCRPanel}
              setShow2={setShowPostContract}
              label={translate("Cost Report Panel")}
              showLocation={"Cost%20Report"}
              disabled={false}
            />
            <DashboardPanel
              showDashboard={showPCPanel}
              setShowDashboard={setShowPCPanel}
              setShow2={setShowPostContract}
              label={translate("Progress Claim Panel")}
              showLocation={"Progress%20Claim"}
              disabled={false}
            />
            <UploadButton
              projectId={project.id}
              packageId={selectedPackage.Id}
              packages={packages}
              postContractReports={postContractReports}
              showLocation={"Cost%20Report"}
              label={translate("Upload Cost Report")}
              Component={PostContractForm}
              postContractFormType={"costReportFields"}
              stateUpdateFunction={async () => {
                const URL = window.location.href;
                const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
                postContractReports.set(
                  await postContractReports.get(
                    project.id,
                    selectedPackage.Id,
                    sheetName
                  )
                );
              }}
              urlEndpoint={
                pc2APIs().upload_form +
                "?projectId=" +
                project.id +
                "&packageId=" +
                selectedPackage.Id +
                "&uploadType=Cost Report"
              }
            />
            <UploadButton
              projectId={project.id}
              packageId={selectedPackage.Id}
              packages={packages}
              postContractReports={postContractReports}
              showLocation={"Progress%20Claim"}
              label={translate("Upload Progress Claim")}
              Component={PostContractForm}
              postContractFormType={"progressClaimFields"}
              stateUpdateFunction={async () => {
                const URL = window.location.href;
                const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
                postContractReports.set(
                  await postContractReports.get(
                    project.id,
                    selectedPackage.Id,
                    sheetName
                  )
                );
              }}
              urlEndpoint={
                pc2APIs().upload_form +
                "?projectId=" +
                project.id +
                "&packageId=" +
                selectedPackage.Id +
                "&uploadType=Progress Claim"
              }
            />
            <EstimateCommentaryModal
              user={user}
              project={project}
              CPs={postContract}
              CP={postContract}
              commentNumber={processComments(
                postContract?.data[0]?.Advice?.Commentary
              )}
              URLs={{
                endpoint: pc2APIs,
                updateStateFunction: async () => {
                  const URL = window.location.href;
                  const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
                  postContract.set(
                    await postContract.get(
                      selectedPackage.ProjectId,
                      selectedReport.Id,
                      sheetName
                    )
                  );
                },
              }}
              CostId={selectedReport.Id}
              Module="PostContract"
            />

            <PostContractControlDelete
              projectId={projectID}
              packageId={selectedPackage.Id}
              postContractId={selectedReport.Id}
              postContractReports={postContractReports}
            />
          </>
        )}
        <PostContractPCRAModalTrigger
          user={user}
          project={project}
          postContract={postContract}
          selectedReport={selectedReport}
        />
      </div>
    </div>
  );
}

PostContractPanelWrapper.propTypes = {
  user: PropTypes.object,
  selectedPackage: PropTypes.object,
  selectedReport: PropTypes.object,
  showPostContract: PropTypes.bool,
  postContract: PropTypes.object,
  setShowPostContract: PropTypes.func,
  setShowCRPanel: PropTypes.func,
  setShowPCPanel: PropTypes.func,
  postContractForm: PropTypes.object,
  project: PropTypes.object,
};

function PostContractPanelWrapper(props) {
  const { user } = props;
  const { selectedPackage } = props;
  const { selectedReport } = props;
  const { showPostContract } = props;
  const { postContract } = props;
  const { setShowPostContract } = props;
  const { setShowCRPanel } = props;
  const { setShowPCPanel } = props;
  const { postContractForm } = props;
  const { project } = props;

  if (
    !window.location.href.includes("dashboard") &&
    !window.location.href.includes("Cost%20Report") &&
    !window.location.href.includes("Progress%20Claim")
  ) {
    return null;
  }

  let title = "";
  if (window.location.href.includes("dashboard")) {
    title = tranStr("Post Contract Control");
  }
  if (window.location.href.includes("Cost%20Report")) {
    title = tranStr("Cost Report Control");
  }
  if (window.location.href.includes("Progress%20Claim")) {
    title = tranStr("Progress Claim Control");
  }

  return (
    <ProjectControlDrawer
      Component={PostContractPanel}
      dashboardType={"POST_CONTRACT"}
      dashboardSelect={"POST_CONTRACT"}
      user={user}
      project={project}
      selectedReport={selectedReport}
      selectedPackage={selectedPackage}
      postContract={postContract}
      postContractForm={postContractForm}
      label={title}
      show1={showPostContract}
      setShow1={setShowPostContract}
      setShow2={setShowCRPanel}
      setShow3={setShowPCPanel}
      setShow4={setShowPCPanel}
      {...props}
    />
  );
}

DashboardPanel.propTypes = {
  showDashboard: PropTypes.bool,
  setShowDashboard: PropTypes.func,
  setShow2: PropTypes.func,
  label: PropTypes.string,
  showLocation: PropTypes.string,
  disabled: PropTypes.bool,
};

export function DashboardPanel(props) {
  const { showDashboard } = props;
  const { setShowDashboard } = props;
  const { setShow2 } = props;
  const { label } = props;
  const { showLocation } = props;
  const { disabled } = props;

  if (!window.location.href.includes(showLocation)) {
    return null;
  }

  if (disabled) {
    return null;
  }

  let classType = "post-contract-control-button-dashboard";
  if (showDashboard) {
    classType = "post-contract-control-button-clicked";
  }

  return (
    <button
      className={classType}
      onClick={() => {
        setShowDashboard(!showDashboard);
        setShow2(false);
      }}
    >
      {label}
    </button>
  );
}

UploadButton.propTypes = {
  label: PropTypes.string,
  showLocation: PropTypes.string,
  disabled: PropTypes.bool,
};

export function UploadButton(props) {
  const { showLocation } = props;
  const { label } = props;
  const { disabled } = props;

  const [modal, setModal] = useState(false);

  if (!window.location.href.includes(showLocation)) {
    return null;
  }

  if (disabled) {
    return null;
  }

  return (
    <div>
      <button
        className="post-contract-control-button-upload"
        onClick={() => {
          setModal(true);
        }}
      >
        {label}
      </button>
      <Modal
        title={label}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        {...props}
      />
    </div>
  );
}

processComments.propTypes = {
  CP: PropTypes.object,
};

export function processComments(CP) {
  // No Commentary so the Comment is number 1
  // The comment is the next in the list
  return isEmpty(CP) || isEmpty(CP.costreport_commentary)
    ? 1
    : CP.costreport_commentary.length + 1;
}

PostContractControlDelete.propTypes = {
  packageId: PropTypes.string,
  projectId: PropTypes.string,
  postContractId: PropTypes.string,
  postContractReports: PropTypes.array,
};

export function PostContractControlDelete(props) {
  const { projectId } = props;
  const { packageId } = props;
  const { postContractId } = props;
  const { postContractReports } = props;

  const [loading, setLoading] = useState(false);

  if (isEmpty(postContractId)) {
    return null;
  }

  const data = {
    projectId: projectId,
    packageId: packageId,
    postContractId: postContractId,
    postContractReports: postContractReports,
  };

  return (
    <DeleteButton
      data={data}
      label={"Delete Report"}
      loading={loading}
      setLoading={setLoading}
      onDelete={onDelete}
    />
  );
}

DeleteButton.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onDelete: PropTypes.func,
  label: PropTypes.string,
  data: PropTypes.object,
};

function DeleteButton(props) {
  const { loading, setLoading } = props;
  const { onDelete } = props;
  const { label } = props;
  const { data } = props;

  if (loading) {
    return <Spinner width={50}></Spinner>;
  } else {
    return (
      <button
        className="post-contract-control-button-delete"
        onClick={() => {
          setLoading(true);
          onDelete(data).then(() => {
            setLoading(false);
          });
        }}
      >
        {translate(label)}
      </button>
    );
  }
}
