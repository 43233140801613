import React, { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import isEmpty from "../../validation/is-empty";
import "./PostContractCommentary.css";
import PropTypes from "prop-types";

import Commentary from "./Commentary";
import { pc2APIs } from "../../api/api-endpoints/PostContract2.0Endpoints";
import { tranStr } from "../../utils/translation";

Clarifications.propTypes = {
  projectID: PropTypes.string,
  postContractId: PropTypes.string,
  commentary: PropTypes.array,
  postContract: PropTypes.object,
};

export default function Clarifications(props) {
  const { projectID } = props;
  const { postContractId } = props;
  const { commentary } = props;
  const { postContract } = props;

  const [error, setError] = useState({});

  const [fieldSet, setFieldSet] = useState([
    {
      comment: "1. The report is based on information available at XXXXX",
      type: "cost_report_clarifications",
    },
    {
      comment: "2. The report incorporates variations up to number YYYYY",
      type: "cost_report_clarifications",
    },
    {
      comment: "3. The next report is due to be issued on ZZZZZ",
      type: "cost_report_clarifications",
    },
  ]);
  const mainHeading =
    "Add Clarifications and Notes below to be displayed on the Post Contract Dashboard.";
  const subHeading = "Post Contract Notes and Clarifications";

  const data = {
    setError: setError,
  };
  useEffect(() => {
    //if there is data in db
    if (!isEmpty(commentary)) {
      //if there are any cost report commentary
      const clarifications = commentary.filter(
        (x) => x.type === "cost_report_clarifications"
      );

      if (!isEmpty(clarifications)) {
        //load only cost report commentary data from db
        setFieldSet(
          clarifications.map((c) => {
            return {
              comment: c.comment,
              type: c.type,
            };
          })
        );
      }
    }
  }, [commentary]);

  return (
    <div className="post-contract-clarifications">
      <Commentary
        fields={fieldSet}
        setFields={setFieldSet}
        mainHeading={mainHeading}
        crType={"cost_report_clarifications"}
        subHeading={subHeading}
        commentary={commentary}
      />

      <div className="general-row-container">
        <Notify error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <SaveButton />
      </div>
    </div>
  );
  function SaveButton() {
    return (
      <button className="general-upload-button" onClick={Save}>
        Save
      </button>
    );
  }

  //Save
  async function Save(e) {
    e.preventDefault();

    const comments = [];

    // Add the changed Clarifications
    fieldSet.forEach((f) => {
      f.post_contract_id = postContractId;
      if (!isEmpty(f.comment)) {
        comments.push(f);
      }
    });

    if (!isEmpty(commentary)) {
      // We need to add exclusions in as well since they share 1 endpoint
      // Otherwise they will be deleted when clarifications are updated
      commentary.forEach((x) => {
        if (x.type === "cost_report_exclusions") {
          comments.push(x);
        }
      });
    }

    data.setError({
      text: "Saving...",
      type: "feedback-success",
    });

    const payload = {
      comments: comments,
      postContractID: postContractId,
      projectID: projectID,
      type: "cost_report",
    };
    const field = JSON.stringify(payload);
    const formData = new FormData();
    formData.append("field", field);

    // POST CONTRACT API
    const url = pc2APIs().save_commentary;

    const config = {
      method: "POST",
      body: formData,
      headers: authHeader({
        authJson: false,
        authForm: true,
        guestJson: false,
      }),
    };

    let response = await fetch(url, config);

    if (response.ok) {
      data.setError({
        text: "Saved Successfully",
        type: "feedback-success",
      });

      const URL = window.location.href;
      const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
      postContract.set(
        await postContract.get(projectID, postContractId, sheetName)
      );
    } else {
      // Response Not OK
      console.log("Network Error");
    }
  }
}

export function Notify(props) {
  const { error } = props;

  return (
    <div className="pcu-error-container">
      <div className={error.type}>
        <b>{tranStr(error.text)}</b>
      </div>
    </div>
  );
}
