import React, { useState, useEffect } from "react";

import { translate } from "../../utils/translation";
import { comma } from "../../helpers/common.functions";

import { hasRoles } from "../../utils/roles";
import { saveCashflowSettings } from "./Cashflow.functions";
import isEmpty from "../../validation/is-empty";

export default function CashflowControlPanel(props) {
  const { cashflowData, cashflowBasis } = props;
  const { user } = props;
  const { estimateID } = props;
  const { totalCost } = props;
  const { project, CPs, CP } = props;

  // State
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedYear, setSelectedYear] = useState(2020);

  // Standard Deviation Range
  const [standardDeviation, setStandardDeviation] = useState(2);
  const [standardDeviationMin, setStandardDeviationMin] = useState(0.1);
  const [standardDeviationMax, setStandardDeviationMax] = useState(20);
  const [program, setProgram] = useState(20);

  const [error, setError] = useState({});

  useEffect(() => {
    if (!isEmpty(cashflowData)) {
      setSelectedMonth(cashflowData?.cashflow_start_month);
      setProgram(cashflowData?.program);
      setSelectedYear(cashflowData?.cashflow_start_year);
      setStandardDeviation(cashflowData?.cashflow_standard_deviation);
    }
  }, [cashflowData]);

  let controlPanel = "cashflow-control-panel";
  if (!hasRoles(user.roles, ["CostManager"])) {
    controlPanel = "cashflow-control-panel-client";
  }

  let payload = {
    project: project,
    projectID: CP.project_id,
    costPlanId: CP.cost_plan_id,
    cashflowBasis: cashflowBasis,
    estimateID,
    selectedMonth,
    selectedYear,
    standardDeviation,
    program: program,
    CPs: CPs,
  };

  return (
    <div className={controlPanel}>
      <div className="cashflow-control-head-row">
        <div className="display-4" style={{ fontSize: "20px" }}>
          {translate("Cashflow Controls")}
        </div>
        <DisplayError error={error} />
        <SaveButton user={user} payload={payload} setError={setError} />
      </div>

      <div className="cashflow-control-row">
        <div className="cashflow-label">{translate("Select Start Month")}</div>
        <Select
          value={selectedMonth}
          set={setSelectedMonth}
          options={months()}
          setError={setError}
        />
        <input
          className="cashflow-date-input"
          value={selectedYear}
          onChange={(e) => {
            setSelectedYear(e.target.value);
            setError({});
          }}
        />
      </div>
      <div className="cashflow-control-row">
        <div className="cashflow-label">{translate("Program Duration")}</div>
        <input
          className="cashflow-program-input"
          value={program}
          onChange={(e) => {
            if (isNaN(e.target.value)) {
              return;
            }

            if (e.target.value.length > 3) {
              return;
            }

            let newProgram = e.target.value;
            setProgram(newProgram);

            // Update the Standard Deviation with Program Change
            let min = newProgram * 0.2 - 10;
            if (min <= 0) {
              min = 0.1;
            }
            setStandardDeviation(newProgram * 0.2);
            setStandardDeviationMin(min);
            setStandardDeviationMax(newProgram * 0.2 + 10);
            setError({});
          }}
        />
        <div className="cashflow-program-label">{translate("Months")}</div>
      </div>

      <div className="cashflow-control-row">
        <div className="cashflow-label">{translate("Standard Deviation")}</div>
        <div className="cashflow-standard-deviation-label">
          <b>{round(standardDeviation, 2)}</b>
        </div>
        <input
          className="cashflow-slider "
          type="range"
          min={standardDeviationMin}
          max={standardDeviationMax}
          step={0.1}
          value={standardDeviation}
          onChange={(e) => {
            setStandardDeviation(e.target.value);
            setError({});
          }}
        />
      </div>

      <div className="cashflow-control-row">
        <div className="cashflow-label">{translate("Construction Cost")}</div>
        <div className="cashflow-standard-deviation-label">
          <b>{comma(Math.round(totalCost))}</b>
        </div>
      </div>
    </div>
  );
}

function SaveButton(props) {
  const { user } = props;
  const { payload } = props;
  const { setError } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="cashflow-save-button-container">
      <button
        className="cashflow-save-button"
        onClick={() => {
          saveCashflowSettings(payload, setError);
        }}
      >
        <i className="fas fa-save" />
      </button>
      <span className="tooltiptext">Save Changes</span>
    </div>
  );
}

function Select(props) {
  const { value, set, options, setError } = props;
  return (
    <select
      className="cashflow-date-input"
      value={value}
      onChange={(e) => {
        set(e.target.value);
        setError({});
      }}
    >
      <SelectList options={options} />
    </select>
  );
}

function SelectList(props) {
  const { options } = props;
  return options.map((item) => {
    return (
      <option key={item.name} value={item.value} className="option">
        {item.name}
      </option>
    );
  });
}

function months() {
  return [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];
}

function round(value, precision) {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="cashflow-feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
