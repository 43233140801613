import React from "react";
import isEmpty from "../../validation/is-empty";
import "./PostContractCommentary.css";
import PropTypes from "prop-types";

PostContractCommentary.propTypes = {
  commentary: PropTypes.object,
  type: PropTypes.object,
};

export default function PostContractCommentary(props) {
  const { commentary, type } = props;
  if (isEmpty(commentary)) {
    return null;
  }

  const pc_commentary = commentary.filter((x) => x.type === type);
  if (isEmpty(pc_commentary)) {
    return null;
  }

  return (
    <div className="pc-commentary-display-area">
      <div className="pc-commentary-table">
        {pc_commentary.map((d) => {
          return (
            <div className="pc-commentary-row" key={d.id}>
              <div className="pc-commentary-bullet" />
              <div className="pc-commentary">{d.comment}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
