import React, { useState } from "react";
import { comma } from "../../helpers/common.functions";
import "./Cashflow.css";
import { saveCashflowSettings } from "./Cashflow.functions";
import { hasRoles } from "../../utils/roles";
import PostContractCashflowToggle from "../cashflow/PostContractCashflowToggle";
import { translate, tranStr } from "../../utils/translation";

import { GENERATED_CASHFLOW } from "../../actions/types";

import PropTypes from "prop-types";

CashflowControlPanel.propTypes = {
  user: PropTypes.object,
  postContract: PropTypes.object,

  standardDeviation: PropTypes.string,
  setStandardDeviation: PropTypes.func,

  standardDeviationMin: PropTypes.string,
  setStandardDeviationMin: PropTypes.func,

  standardDeviationMax: PropTypes.string,
  setStandardDeviationMax: PropTypes.func,

  program: PropTypes.string,
  setProgram: PropTypes.func,

  selectedMonth: PropTypes.string,
  setSelectedMonth: PropTypes.func,

  selectedYear: PropTypes.string,
  setSelectedYear: PropTypes.func,

  cashflowBasis: PropTypes.string,
  setCashflowBasis: PropTypes.func,

  totalCost: PropTypes.string,

  project: PropTypes.object,
};

export default function CashflowControlPanel(props) {
  const { user, postContract } = props;
  const { standardDeviation } = props;
  const { setStandardDeviation } = props;
  const { standardDeviationMin } = props;
  const { setStandardDeviationMin } = props;
  const { standardDeviationMax } = props;
  const { setStandardDeviationMax } = props;
  const { program } = props;
  const { setProgram } = props;
  const { selectedMonth } = props;
  const { setSelectedMonth } = props;
  const { selectedYear } = props;
  const { setSelectedYear } = props;
  const { cashflowBasis, setCashflowBasis } = props;
  const { totalCost } = props;
  const { project } = props;

  const [error, setError] = useState({});

  let cashflow_control;
  let controlPanel = "post-contract-cashflow-control-panel";
  if (!hasRoles(user.roles, ["CostManager"])) {
    controlPanel = "post-contract-cashflow-control-panel-client";
  }

  //Checks for the porject's post_contract_cashflow_basis
  if (cashflowBasis === GENERATED_CASHFLOW) {
    cashflow_control = "cashflow-generated-control-active";
  } else {
    cashflow_control = "cashflow-generated-control-inactive";
  }

  return (
    <div className={controlPanel}>
      <div className="post-contract-cashflow-control-head-row">
        <div className="display-4" style={{ fontSize: "20px" }}>
          {translate("Cashflow Controls")}
        </div>
        <DisplayError error={error} />
        <SaveButton
          user={user}
          project={project}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          standardDeviation={standardDeviation}
          program={program}
          cashflowBasis={cashflowBasis}
          postContractId={postContract.data[0].PostContractId}
          packageId={postContract.data[0].PackageId}
          setError={setError}
        />
      </div>

      <div className="post-contract-cashflow-control-row">
        <div className="post-contract-cashflow-label">
          {translate("Cashflow Source")}
        </div>
        <PostContractCashflowToggle
          cashflowBasis={cashflowBasis}
          projectID={project.id}
          setCashflowBasis={setCashflowBasis}
        />
      </div>
      <div className={cashflow_control}>
        <div className="post-contract-cashflow-control-row">
          <div className="post-contract-cashflow-label">
            {translate("Select Start Month")}
          </div>
          <Select
            value={selectedMonth}
            set={setSelectedMonth}
            options={months()}
            setError={setError}
          />
          <input
            className="post-contract-cashflow-date-input"
            value={selectedYear}
            onChange={(e) => {
              setSelectedYear(e.target.value);
              setError({});
            }}
          />
        </div>
        <div className="post-contract-cashflow-control-row">
          <div className="post-contract-cashflow-label">
            {translate("Program Duration")}
          </div>
          <input
            className="post-contract-cashflow-program-input"
            value={program}
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                return;
              }

              if (e.target.value.length > 3) {
                return;
              }

              let newProgram = e.target.value;
              setProgram(newProgram);

              // Update the Standard Deviation with Program Change
              let min = newProgram * 0.2 - 10;
              if (min <= 0) {
                min = 0.1;
              }
              setStandardDeviation(newProgram * 0.2);
              setStandardDeviationMin(min);
              setStandardDeviationMax(newProgram * 0.2 + 10);
              setError({});
            }}
          />
          <div className="post-contract-cashflow-program-label">
            {translate("Months")}
          </div>
        </div>

        <div className="post-contract-cashflow-control-row">
          <div className="post-contract-cashflow-label">
            {translate("Forecast Curve")}
          </div>
          <div className="post-contract-cashflow-standard-deviation-label">
            <b>{round(standardDeviation, 2)}</b>
          </div>
          <input
            className="post-contract-cashflow-slider "
            type="range"
            min={standardDeviationMin}
            max={standardDeviationMax}
            step={0.1}
            value={standardDeviation}
            onChange={(e) => {
              setStandardDeviation(e.target.value);
              setError({});
            }}
          />
        </div>

        <div className="post-contract-cashflow-control-row">
          <div className="post-contract-cashflow-label">
            {translate("Forecast Adjusted Contract Sum")}
          </div>
          <div className="post-contract-cashflow-standard-deviation-label">
            <b>{comma(Math.round(totalCost))}</b>
          </div>
        </div>
      </div>
    </div>
  );

  function months() {
    return [
      { name: "January", value: 1 },
      { name: "February", value: 2 },
      { name: "March", value: 3 },
      { name: "April", value: 4 },
      { name: "May", value: 5 },
      { name: "June", value: 6 },
      { name: "July", value: 7 },
      { name: "August", value: 8 },
      { name: "September", value: 9 },
      { name: "October", value: 10 },
      { name: "November", value: 11 },
      { name: "December", value: 12 },
    ];
  }

  function round(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}

function Select(props) {
  const { value, set, options, setError } = props;
  return (
    <select
      className="post-contract-cashflow-date-input"
      value={value}
      onChange={(e) => {
        set(e.target.value);
        setError({});
      }}
    >
      <SelectList options={options} />
    </select>
  );
}

function SelectList(props) {
  const { options } = props;
  return options.map((item, i) => {
    return (
      <option key={i} value={item.value} className="option">
        {item.name}
      </option>
    );
  });
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="post-contract-cashflow-feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

SaveButton.propTypes = {
  user: PropTypes.object,
  project: PropTypes.object,
  selectedMonth: PropTypes.string,
  selectedYear: PropTypes.string,
  standardDeviation: PropTypes.string,
  program: PropTypes.string,
  cashflowBasis: PropTypes.string,
  postContractId: PropTypes.string,
  packageId: PropTypes.string,
  setError: PropTypes.func,
};

function SaveButton(props) {
  const { user } = props;
  const { project } = props;
  const { selectedMonth } = props;
  const { selectedYear } = props;
  const { standardDeviation } = props;
  const { program } = props;
  const { cashflowBasis } = props;
  const { postContractId } = props;
  const { packageId } = props;
  const { setError } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  let text = tranStr("Use Template");
  let tooltip = "Set Cashflow to be based on the uploaded template";
  if (cashflowBasis === GENERATED_CASHFLOW) {
    text = tranStr("Generate Cashflow");
    tooltip = "Generate cashflow if currently empty or save any changes";
  }

  return (
    <div className="post-contract-cashflow-save-button-container">
      <button
        className="post-contract-cashflow-save-button"
        onClick={() => {
          const payload = {
            projectId: project.id,
            startMonth: selectedMonth,
            startYear: selectedYear,
            standardDeviation,
            program,
            cashflowBasis,
            postContractId,
            packageId,
          };

          saveCashflowSettings(payload, project, setError);
        }}
      >
        {text}
      </button>
      <span className="tooltiptext">{translate(tooltip)}</span>
    </div>
  );
}
