import React, { useState } from "react";
import { translate } from "../../utils/translation";
// Components
import Spinner from "../common/Spinner";
import CompanyEmployeesTable from "./CompanyEmployeesTable";
import isEmpty from "../../validation/is-empty";

// API
import { useAllEmployees, useEmployees } from "../../api/admin/CompanyAPI";

// Functions
import { saveEmployees } from "./CompanyEmployees.functions";

// Styles
import "./CompanyEmployees.css";

export default function CompanyEmployees(props) {
  const { company, setCompany, companies } = props;
  const { show } = props;
  const { allEmployees } = useAllEmployees(show);
  const { employees } = useEmployees(company, show);

  const [error, setError] = useState({});

  const data = {
    company: company,
    setCompany: setCompany,
    companies: companies,
    selectedUsers: employees.data,
    setError: setError,
  };

  if (isEmpty(allEmployees.data)) {
    return <Spinner marginTop={"170px"} marginBottom={"170px"} />;
  }

  return (
    <div>
      <CompanyEmployeesTable
        data={allEmployees.data}
        dataLoading={allEmployees.loading}
        selectedDataLoading={employees.loading}
        selectedUsers={employees.data}
        setSelectedUsers={employees.set}
      />
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-row-container-double">
        <button
          className="general-upload-button"
          onClick={() => {
            saveEmployees(data);
          }}
        >
          {translate("Save Employees")}
        </button>
      </div>
    </div>
  );
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="company-create-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
