import React, { useState, useEffect } from "react";
import TextEditor from "../text-editor/TextEditor";
import { translate, tranStr } from "../../utils/translation";
import { DisplayError } from "./EstimateCommentary";
import {
  commentary,
  uploadFile,
  documentation,
  program,
  procurement,
  contingency,
  assumptions,
  costreportcommentary,
} from "./EstimateCommentary.functions";
import "./EstimateCommentaryModal.css";

export default function EstimateCommentaryTableEditor(props) {
  const { CP } = props;
  const { CPs } = props;
  const { ectBasis } = props;
  const { project_id, commentNumber } = props;
  const { project } = props;
  const { setShow } = props;
  const { URLs } = props;
  const { CostId } = props;
  const { Module } = props;
  // Titles
  const [title, setTitle] = useState(
    "Documentation Used to Prepare This Cost Plan"
  );

  const [showTitle, setShowTitle] = useState(true);
  const [customTitle, setCustomTitle] = useState("");
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [error, setError] = useState({});

  useEffect(() => {
    if (Module === "PostContract") setTitle("Custom Title");
  }, [setTitle, Module]);

  useEffect(() => {
    if (title === "Custom Title") {
      setShowTitle(true);
    } else {
      setShowTitle(false);
    }
  }, [title]);

  useEffect(() => {
    const commentaryValues = [
      documentation,
      program,
      procurement,
      contingency,
      assumptions,
      "",
    ];

    setText(commentaryValues[commentary.indexOf(title)]);
  }, [title]);

  if (ectBasis !== "TABLE") {
    return null;
  }

  const showHideTitleInput = showTitle
    ? "commentary-title-flexbox"
    : "commentary-title-none";

  let data = {
    title: title,
    customTitle: customTitle,
    project_id: project_id,
    project: project,
    stage: CP.stage,
    revision: CP.version,
    file: file,
    part: commentNumber,
    showTitle: showTitle,
    CPs: CPs,
    setShow: setShow,
    error: error,
    text: text,
    CP: CP,
    setError: setError,
    CostId: CostId,
    Module: Module,
  };

  return (
    <div>
      <div className="commentary-title-flexbox">
        <label className="label-title-selectbox">
          {translate("Select Title")}:
        </label>
        <select
          className="commentary-select-box"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        >
          {selectTitle(
            Module === "PostContract" ? costreportcommentary : commentary
          )}
        </select>
      </div>
      <div className={showHideTitleInput}>
        <label className="label-title-input">{translate("Enter Title")}:</label>
        <input
          className="input-custom-title"
          value={customTitle}
          onChange={(e) => {
            setCustomTitle(e.target.value);
          }}
        />
      </div>
      <TextEditor text={text} setText={setText} />
      <div className="general-row-container">
        <FileTray file={file} />
      </div>
      <div className="general-row-container">
        <UploadFile setFunction={setFile} name={translate("Add File")} />
      </div>
      <div className="general-row-container">
        <DisplayError error={error} />
      </div>
      <div className="general-button-container">
        <button
          className="general-upload-button"
          onClick={() => {
            uploadFile(data, URLs);
          }}
        >
          {translate("Save")}
        </button>
      </div>
    </div>
  );

  function selectTitle(titles) {
    return titles.map(function (thisTitle, i) {
      return (
        <option key={i} value={thisTitle}>
          {thisTitle}
        </option>
      );
    });
  }
}

function UploadFile(props) {
  const { setFunction, name } = props;

  return (
    <label className="general-upload-button">
      <input
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFunction(e.target.files[0]);
          }
        }}
      />
      {name}
    </label>
  );
}

function FileTray(props) {
  const { file } = props;
  let fileName = tranStr("No File Added");

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="category-edit-file-display">
      {tranStr("File") + ": " + fileName}
    </div>
  );
}
