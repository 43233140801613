import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import "./PostContractTimeBar.css";
import PropTypes from "prop-types";

PostContractTimeBar.propTypes = {
  currentMonth: PropTypes.number,
  totalMonths: PropTypes.number,
};

export default function PostContractTimeBar(props) {
  const { currentMonth } = props;
  const { totalMonths } = props;

  // Validation
  if (isEmpty(currentMonth)) {
    return null;
  }

  const width = 300;

  return (
    <div className="timebar">
      <Bar width={width} fill={currentMonth} background={totalMonths} />
      <Text fill={currentMonth} background={totalMonths} />
    </div>
  );
}

function Bar(props) {
  const { width, fill, background } = props;

  return (
    <div className="timebar-stacked-bar">
      <BackgroundBar width={width} />
      <FillBar width={width} fill={fill} background={background} />
    </div>
  );
}

function BackgroundBar(props) {
  const { width } = props;
  return (
    <div className="timebar-background-bar" style={{ width: width }}></div>
  );
}

function FillBar(props) {
  const { width, fill, background } = props;

  let ratio = (fill / background) * width;
  let values = generateAnimatedValues(ratio);
  if (ratio === Infinity) {
    ratio = 0;
  }

  return (
    <div className="timebar-fill-bar">
      <svg height="30px" width="300px">
        <rect className="timebar-fill" width={ratio}>
          <animate
            attributeType="CSS"
            attributeName="width"
            from="0"
            to="1"
            values={values}
            keyTimes="0; 0.25; 0.50; 0.75; 1"
            dur="0.7s"
          />
        </rect>
      </svg>
    </div>
  );
}

function Text(props) {
  const { fill, background } = props;

  let percentage = (fill / background) * 100;
  let displayedPercentage = Math.round(percentage) + "%";
  if (isNaN(percentage)) {
    displayedPercentage = "N/A";
  }

  return (
    <div className="timebar-label">
      <div className="timebar-label-value">
        <b>{displayedPercentage}</b>
      </div>
      <div className="timebar-label-text">{translate("Program Elapsed")}</div>
    </div>
  );
}

function generateAnimatedValues(ratio) {
  return (
    "" +
    ratio * 0 +
    ";" +
    ratio * 0.25 +
    ";" +
    ratio * 0.5 +
    ";" +
    ratio * 0.75 +
    ";" +
    ratio * 1 +
    ""
  );
}
