import React from "react";
import PropTypes from "prop-types";

import "./PostContractPaymentDetails.css";
import isEmpty from "../../validation/is-empty";

PostContractPaymentDetails.propTypes = {
  Details: PropTypes.object,
};

export default function PostContractPaymentDetails(props) {
  const { Details } = props;

  if (isEmpty(Details)) {
    return null;
  }

  const rows = [];

  Object.keys(Details).forEach((key) => {
    rows.push({
      name: key,
      value: "",
    });
  });

  Object.values(Details).forEach((value, i) => {
    rows[i].value = value;
  });

  const display = [];
  rows.forEach((row, i) => {
    let type = "progress-claim-details-box3";
    if (i === 0) {
      type = "progress-claim-details-box2";
    }

    if (i === 1 || i === 2) {
      type = "progress-claim-details-box1";
    }

    display.push(
      <GridItem key={row.name} value={row.value} text={row.name} type={type} />
    );
  });

  return <div className="progress-claim-details">{display}</div>;
}

GridItem.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
};

function GridItem(props) {
  const { value, text, type } = props;

  return (
    <div className="progress-claim-details-item">
      <div className={type}>
        <b>{value}</b>
      </div>
      <div className="progress-claim-details-label">{text}</div>
    </div>
  );
}
