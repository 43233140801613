import React, { useState } from "react";
import { connect } from "react-redux";
import "./EstimateBreakdownComment.css";
import ProfileModal from "../profile/ProfileModal";
import {
  onSend,
  onDelete,
  onAction,
  formatAMPM,
} from "./EstimateBreakdownComment.functions";
import { comma, commaToFixed } from "../../helpers/common.functions";
import { translate, tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import { displayUserImage } from "../../validation/image-clean";
import { ImageDisplay } from "../images/ImageDisplay";

function EstimateBreakdownComment(props) {
  const { show } = props;
  const { breakdown } = props;
  const { projectID } = props;
  const { CPs } = props;
  const { project } = props;
  const { user } = props.auth;
  const { rowID } = props;
  let { filtered } = props;

  const { setModal } = props; // is non-null when EstimateBreakdownComment is used as a Component of Modal

  const [comment, setComment] = useState("");
  const [error, setError] = useState({});

  if (!show) {
    return null;
  }

  // Extend breakdown object
  breakdown.projectID = projectID;
  breakdown.comment = comment;
  breakdown.setError = setError;
  breakdown.setModal = setModal;
  breakdown.summaryID = rowID;

  return (
    <div className="ebc-container">
      <div className="ebc-title"></div>
      {filtered === undefined && (
        <div className="ebc-table">
          <div className="ebc-heading-row">
            <div className="ebc-description">
              <b>{translate("Description")}</b>
            </div>
            <div className="ebc-quantity">
              <b>{translate("Quantity")}</b>
            </div>
            <div className="ebc-unit_of_measure">
              <b>{translate("Unit")}</b>
            </div>
            <div className="ebc-rate">
              <b>{translate("Rate")}</b>
            </div>
            <div className="ebc-subtotal">
              <b>{translate("Total")}</b>
            </div>
          </div>
          <div className="ebc-row">
            <div className="ebc-description">{breakdown.description}</div>
            <div className="ebc-quantity">{breakdown.quantity}</div>
            <div className="ebc-unit_of_measure">
              {breakdown.unit_of_measure}
            </div>
            <div className="ebc-rate">
              {breakdown.rate ? commaToFixed(breakdown.rate) : null}
            </div>
            <div className="ebc-subtotal">{comma(breakdown.subtotal)}</div>
          </div>
        </div>
      )}
      <CommentsDisplay
        breakdown={breakdown}
        comments={breakdown.comments}
        user={user}
        CPs={CPs}
        project={project}
        filtered={filtered}
      />
      <div className="ebc-new-comment-container">
        <div className="ebc-new-comment">
          <ImageDisplay
            image={displayUserImage(user)}
            imageWidth={"30px"}
            imageHeight={"30px"}
            borderRadius={"15px"}
            isBezierDisabled={true}
          />
          <textarea
            className="ebc-comment"
            placeholder={tranStr("Type Comment Here")}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <button
            className="ebc-send"
            onClick={async () => {
              await onSend(breakdown, CPs, project, setError);
            }}
          >
            {translate("Send")}
          </button>
        </div>
      </div>
      <div className="general-row-container">
        <DisplayError error={error} />
      </div>
      <div className="general-button-container">
        <button
          className="general-upload-button"
          onClick={() => {
            onAction(breakdown, CPs, project);
          }}
        >
          {translate("Close Out")}
        </button>
      </div>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(EstimateBreakdownComment);

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="ebc-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function CommentsDisplay(props) {
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const { comments } = props;
  const { breakdown } = props;
  const { CPs } = props;
  const { project } = props;
  const { user } = props;
  const { filtered } = props;

  let commentsDisplay = [];

  if (isEmpty(comments)) {
    commentsDisplay = <div>{tranStr("Comments will appear here.")}</div>;
  }

  comments.forEach((comment) => {
    let date;
    if (!isNaN(comment.timestamp)) {
      date = new Date(parseInt(comment.timestamp));
    } else {
      date = new Date(comment.timestamp);
    }
    let day = date.toLocaleString("default", {
      day: "numeric",
    });
    let month = date.toLocaleString("default", {
      month: "long",
    });
    let year = date.toLocaleString("default", {
      year: "numeric",
    });

    let time = formatAMPM(date);
    commentsDisplay.push(
      <div className="ebc-comment-card-container" key={comment.id}>
        <div className="ebc-avatar-comment-container">
          <button
            onClick={() => {
              setSelectedUser(comment.user);
              setShow(true);
            }}
          >
            <ImageDisplay
              image={displayUserImage(comment.user)}
              imageWidth={"30px"}
              imageHeight={"30px"}
              borderRadius={"15px"}
            />
          </button>
        </div>

        <div className="ebc-comment-card">
          <div className="ebc-comment-top-row">
            <div className="ebc-comment-details">
              <div className="ebc-comment-user-name">{comment.user.name}</div>
              <div className="ebc-comment-user-surname">
                {comment.user.surname}
              </div>
              <div className="ebc-comment-user-surname">-</div>
              <div className="ebc-comment-user-surname">{day}</div>
              <div className="ebc-comment-user-surname">{month}</div>
              <div className="ebc-comment-user-surname">{year}</div>
              <div className="ebc-comment-user-surname">-</div>
              <div className="ebc-comment-user-surname">{time}</div>
            </div>
            <CommentDeleteButton
              user={user}
              breakdown={breakdown}
              author={comment.user}
              commentID={comment.id}
              CPs={CPs}
              project={project}
            />
          </div>

          <div className="ebc-comment-text">{comment.text}</div>
        </div>
      </div>
    );
  });

  let commentThreadType = "ebc-comment-thread";
  if (!isEmpty(filtered)) {
    commentThreadType = "ebc-comment-thread-filtered";
  }

  return (
    <div className={commentThreadType}>
      {commentsDisplay}
      <ProfileModal
        user={selectedUser}
        show={show}
        setShow={setShow}
        projectName={"Cost Clarity"}
      />
    </div>
  );
}

function CommentDeleteButton(props) {
  const { user } = props;
  const { commentID } = props;
  const { breakdown } = props;
  const { CPs } = props;
  const { project } = props;
  const { author } = props;

  if (author.id !== user.id) {
    return null;
  }

  return (
    <button
      className="ebc-comment-delete-button"
      onClick={() => {
        onDelete(commentID, CPs, project, breakdown);
      }}
    >
      <i className="fas fa-times" />
    </button>
  );
}
